import platform from "platform";
import { DeviceType } from "../../../models/Report";

export interface IDeviceInfo {
  deviceType: DeviceType | null;
  deviceOS: string | null;
  browser: string | null;
  viewportSize: string | null;
  screenSize: string | null;
}

export function getDeviceInfo(userAgent: string, screenSize?: string | null, viewportSize?: string | null ): IDeviceInfo {
  const parsedPlatform = platform.parse(userAgent);
  const deviceType = isMobileDevice(parsedPlatform) ? DeviceType.mobile : DeviceType.desktop;
  const browser = parsedPlatform.name || null;
  const deviceOS = parsedPlatform.os?.family?.replace("OS X", "Mac OS") || null;

  return { deviceType, deviceOS, browser, viewportSize: viewportSize || null, screenSize: screenSize || null };
}

function isMobileDevice(platform: any): boolean {
  const os = platform.os?.family?.toLowerCase();
  const ua = platform.ua?.toLowerCase();

  return (
    os?.includes("android") ||
    os?.includes("ios") ||
    os?.includes("iphone") ||
    os?.includes("mobile") ||
    ua?.includes("mobile") ||
    ua?.includes("android") ||
    ua?.includes("iphone")
  );
};