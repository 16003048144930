import React from "react";
import { useTranslation } from "react-i18next";

import "../../../tailwind.generated.css";

import ImageWithZoom from "../ImageWithZoom";
import ReportBlockHeader from "../ReportBlockHeader";
import AlphabetBullet from "../../TestEditor/AlphabetBullet";

import { IPreferencePairwiseComparisonResponse, IPreferenceResponse } from "../../../models/Response";
import { IPreferenceOptionReply } from "../../../models/Test";

interface IPairwiseReportProps {
  replies: IPreferenceOptionReply[];
  responses: IPreferencePairwiseComparisonResponse[];
  questionText: string;
}

export default function PairwiseReport(props: IPairwiseReportProps) {
  const { t } = useTranslation();

  const findReplyDataById = (id: string) => {
    const reply = props.replies.find((element) => element.id === id);
    return { replyValue: reply?.replyValue, image: reply?.image, index: props.replies.findIndex((element) => element.id === id) };
  };

  // Calculate total possible comparisons for each option
  const calculateWinRate = () => {
    const wins: Record<string, number> = {};
    const totalComparisons: Record<string, number> = {};

    // Initialize counters
    props.replies.forEach((reply) => {
      wins[reply.id] = 0;
      totalComparisons[reply.id] = 0;
    });

    // Count wins and total comparisons
    props.responses.forEach((response) => {
      response.selectedOptions.forEach((option) => {
        if (option.winner) {
          wins[option.winner]++;
        }
        // Count total comparisons for each option
        if (option.option1) totalComparisons[option.option1]++;
        if (option.option2) totalComparisons[option.option2]++;
      });
    });

    // Calculate win rates
    const winRates: Record<string, number> = {};
    Object.keys(wins).forEach((id) => {
      winRates[id] = totalComparisons[id] > 0 
        ? (wins[id] / totalComparisons[id]) * 100 
        : 0;
    });

    return { winRates, wins, totalComparisons };
  };

  const { winRates, wins } = calculateWinRate();


  // Sort options by win rate in descending order
  const sortedOptions = Object.keys(winRates).sort((a, b) => winRates[b] - winRates[a]);

  return (
    <div className="pairwise-report flex flex-col gap-4">
      <div className="pairwise-report__question">
        <span className="block caption">{t("Question")} </span>
        <ReportBlockHeader questionText={props.questionText} className="mb-4" />
      </div>
      <div className="flex flex-col gap-1">
        <span className="captionNoMargin">{t("Win rate")}</span>
        <span className="text-gray-600 text-sm">{t("% of comparisons in which an option was chosen as the winner")}</span>
      </div>
      {sortedOptions.map((key) => (
        <div key={`pairwise-icon-${key}`}>
          <div className="flex justify-between w-full items-center mt-2">
            <div className="flex items-center justify-center">
              <div className="pairwise-report__option relative">
                <ImageWithZoom
                  imageSrc={findReplyDataById(key).image}
                  className="rounded-md w-12 h-12 object-contain bg-gray-200"
                />
              </div>
            </div>
            <div className="flex-1 pl-6 mt-1">
              <div className="pairwise-report__progress h-1 bg-gray-300 rounded-full w-full relative">
                <div
                  className={`pairwise-report__progress-bar h-1 rounded-full bg-purple-500`}
                  style={{
                    width: `${winRates[key]}%`,
                  }}
                ></div>
              </div>
              <div className="flex justify-between items-center mt-2">
                <div className="pairwise-report__label font-medium flex items-center gap-2">
                  <AlphabetBullet optionIndex={findReplyDataById(key).index} />
                  {findReplyDataById(key).replyValue}
                </div>
                <div className="pairwise-report__stats font-medium">
                  {Math.round(winRates[key])}%
                  <span className="font-normal text-gray-600">
                    &nbsp;({wins[key]}{" "}{t("wins", { count: wins[key] })})
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
