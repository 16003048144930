import React, { useEffect, useRef } from "react";
import _ from "lodash";
import "../../tailwind.generated.css";

import { FIGMA_PROTOTYPES_BASE_URL } from "./constants";
import useFigmaContext from "./hooks/useFigmaContext";
import { IPrototypeOptions } from './Models';
import { CanvasSize } from '../../models/Response';

const DEFAULT_PROTOTYPE_BG_COLOR = "000000";
const DEFAULT_PROTOTYPE_SCALING = "scale-down";

const getPrototypeUrl = (options: IPrototypeOptions) => {
  const { fileId, startNodeId, fileVersion, scaling = DEFAULT_PROTOTYPE_SCALING, bg = DEFAULT_PROTOTYPE_BG_COLOR } = options;
  let url = `${FIGMA_PROTOTYPES_BASE_URL}/proto/${fileId}/fileName?starting-point-node-id=${encodeURIComponent(startNodeId)}&hide-ui=1&hotspot-hints=0&scaling=${scaling}&version-id=${''}&disable-default-keyboard-nav=1&bg-color=${bg}&node-id=${encodeURIComponent(startNodeId)}`;
  if (options.debug) url += "&debug=true";
  if (options.watchscroll) url += "&watchscroll=true";
  if (options.video) url += "&video=true";
  if (options.fps) url += `&fps=${options.fps}`;
  url += `&pwhost=${window.location.host}`;
  url += '&client-id=8I3AkJdCHnouKW9yBMpkRn&embed-host=pathway-app';
  return url;
};

interface FigmaIFrameProps {
  options: IPrototypeOptions;
  width: number | string;
  height: number | string;
  className?: string;
  onPresentedNodeChanged?: (data?: any) => void;
  onClick?: (data?: any) => void;
  onLoad?: () => void;
  onUploadComplete?: () => void;
  onSizeRetrieved?: (data: CanvasSize) => void;
};

export default function FigmaIFrame(props: FigmaIFrameProps) {

  const prototypeUrl = getPrototypeUrl(props.options);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const figmaContext = useFigmaContext();

  useEffect(() => {
    if (figmaContext && !figmaContext.sendMessageHandler) {
      figmaContext?.setCallback(onMessageCallback);
    }

    return () => figmaContext?.removeCallback();
  }, [figmaContext]);

  useEffect(() => {
    window.addEventListener("message", messageHandler);
    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, [props.onPresentedNodeChanged, props.onClick, props.onLoad, props.onUploadComplete, figmaContext]);

  function onMessageCallback(message: any) {
    iframeRef?.current?.contentWindow?.postMessage(message, "*");
  }

  function messageHandler(event: MessageEvent) {
    const { type, data: eventData } = event.data;
    // console.log('EVENT FROM FIGMA', type, eventData);

    try {
      figmaContext?.handleEvent(event);
    } catch (error) {
      console.warn('Error in figma context handler', error);
    }

    if (type === "MOUSE_PRESS_OR_RELEASE") {
      if (!eventData.targetNodeId) return;
      try {
        const event = {
          clickData: {
            nodeId: eventData.targetNodeId,
            x: !!eventData.nearestScrollingFrameMousePosition ? eventData.nearestScrollingFrameMousePosition.x : eventData.targetNodeMousePosition.x,
            y: !!eventData.nearestScrollingFrameMousePosition ? eventData.nearestScrollingFrameMousePosition.y : eventData.targetNodeMousePosition.y,
            ...eventData
          }
        };
        props.onClick?.(event);
      } catch (error) {
        console.error(error, eventData);
      }
    }
    if (type === "INITIAL_LOAD") {
    }

    if (type === "PW_RECORDER_CREATED") {
      props.onLoad?.();
    }

    if (type === "PW_CANVAS_SIZE") {
      props.onSizeRetrieved?.(eventData as any);
    }

    if (type === "PRESENTED_NODE_CHANGED") {
      props.onPresentedNodeChanged?.(eventData);
    }

    if (type === "UPLOAD_COMPLETE") {
      console.log("UPLOAD_COMPLETE", eventData);
      props.onUploadComplete?.();
    }

  }

  return <iframe id={props.options.fileId + "_figma"} className={props.className} height={props.height} width={props.width} src={prototypeUrl} ref={iframeRef} />;
}
