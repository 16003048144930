import React, { useCallback, useRef, useEffect, useState } from "react";
import { formatTime } from "../../utils";
import rrwebPlayer from "rrweb-player";

import MuteUnmuteButton from "./MuteUnmuteButton";

import { IconPause, IconPlay } from "../../../../../icons";

interface IPlayerControllerButtonsProps {
  replayer: rrwebPlayer;
  currentTime?: number;
  totalTime?: number;
  playerState?: string;
  videoFaceRef: React.RefObject<HTMLVideoElement> | null;
  hasAudio: boolean;
}

export default function FigmaPlayerControllerButtons(props: IPlayerControllerButtonsProps) {
  const progressRef = useRef<HTMLDivElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const [time, setTime] = useState('00:00');
  const [percent, setPercent] = useState(0);
  const percentage = `${100 * percent}%`;

  const handleProgressClick = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const progress = progressRef.current;
    const face = props.videoFaceRef?.current;

    if (progress && props.replayer) {
      const progressRect = progress.getBoundingClientRect();
      const x = event.clientX - progressRect.left;
      let percent = x / progressRect.width;

      percent = Math.max(0, Math.min(1, percent));
      const timeOffset = (props.totalTime || 0) * percent;

      setTime(formatTime(timeOffset) || '00:00');
      setPercent(percent);

      props.replayer.goto(timeOffset);

      if (face) {
        face.currentTime = timeOffset / 1000; // Convert ms to seconds
      }
    }
  }, [props.replayer, props.totalTime]);

  const handlePlayPause = useCallback(() => {
    if (props.videoFaceRef?.current) {
      if (props.videoFaceRef.current.paused) {
        props.videoFaceRef.current.play();
      } else {
        props.videoFaceRef.current.pause();
      }
    }

    setIsPlaying(prev => !prev);
    props.replayer.toggle();
  }, [props.replayer, props.videoFaceRef]);

  useEffect(() => {
    if (props.currentTime !== undefined && props.totalTime !== undefined) {
      const newTime = formatTime(props.currentTime) || '00:00';
      const newPercent = Math.min(1, props.currentTime / props.totalTime);
      setTime(newTime);
      setPercent(newPercent);

      // Check if the video has ended
      const threshold = 0.99; // 99% of the video
      if (newPercent >= threshold) {
        setIsPlaying(false);
      }
    }
  }, [props.currentTime, props.totalTime]);

  return (
    <div className="flex items-center px-4 gap-4 p-4 border-t-1 border-gray-800">
      <button onClick={handlePlayPause}>
        {isPlaying ? <IconPause className="fill-current text-white hover:opacity-75 transition-opacity" /> : <IconPlay className="fill-current text-white hover:opacity-75 transition-opacity" />}
      </button>

      <div
        className="w-full bg-gray-300 rounded-full h-2 my-2 cursor-pointer"
        ref={progressRef}
        onClick={(e) => handleProgressClick(e)}
      >
        <div
          className="bg-blue-500 rounded-full h-2"
          style={{ width: percentage }}
        ></div>
      </div>

      <div className='video-timer text-xs text-center text-white font-bold w-[40px]'>
        {time}
      </div>

      {props.hasAudio && props.videoFaceRef && <MuteUnmuteButton videoRef={props.videoFaceRef} />}
    </div>
  );
}