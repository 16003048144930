import React from "react";
import { PlayPauseButton, SeekBar, TimeDisplay, MuteUnmuteButton } from ".";
import { useVideoControls } from "../hooks";


type PlayerControlsProps = {
  additionalVideoRef?: React.RefObject<HTMLVideoElement>;
  mainVideoRef: React.RefObject<HTMLVideoElement>;
  hasAudio: boolean;
  videoOffsets: { startOffset: number, duration: number };
  duration: number;
}

export default function PlayerControls(props: PlayerControlsProps) {
  const { seekBarValue, changeSeekBarValue, isPlaying, togglePlayPause } = useVideoControls({ mainVideoRef: props.mainVideoRef, additionalVideoRef: props.additionalVideoRef, videoOffsets: props.videoOffsets, duration: props.duration });

  // console.log('videoTimings', props.videoOffsets, props.mainVideoRef.current, props.additionalVideoRef?.current);
  return (
    <div className="flex items-center px-4 gap-4 p-4 border-t-1 border-gray-800 bg-black">
      <PlayPauseButton isPlaying={isPlaying} togglePlayPause={togglePlayPause} />
      <SeekBar seekBarValue={seekBarValue} onChange={changeSeekBarValue} />
      <TimeDisplay videoRef={props.mainVideoRef} />
      {props.hasAudio && <MuteUnmuteButton videoRef={props.mainVideoRef} />}
    </div>
  )
}