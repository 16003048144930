import React, { useEffect, useState } from "react";
import clsx from "clsx";
import _ from "lodash";
import { ICardSortResponse } from "../../../models/Response";
import { getShare } from "./utils";

import Tooltip from "../../Tooltip";

import RenderIfVisible from "react-render-if-visible";

type MatrixTableProps = {
  blockId: string;
  rows: {
    id: string;
    value: string;
    description: string | null | undefined;
    image: string | null | undefined;
  }[];
  columns: {
    id: string;
    value: string | undefined;
  }[];
  hiddenCategories?: string[];
  cardsData: {
    [cardId: string]: {
      [categoryKey: string]: number; // categoryKey: `${categoryId}:${categoryValue}` - для отдельного отчета респондента и categoryId - для общего отчета по всем юзерам
    };
  };
  individual: boolean;
  response: ICardSortResponse | undefined;
  totalResponses?: number;
};

const MatrixTable: React.FC<MatrixTableProps> = ({ blockId, rows, columns, hiddenCategories, cardsData, individual, response, totalResponses }) => {
  const [withScroll, setWithScroll] = useState<boolean | null>(null);

  useEffect(() => {
    const tableElement = document.getElementById(`matrixTableWrapper-${blockId}`);
    if (tableElement) {
      const withScroll = tableElement.scrollWidth > tableElement.clientWidth;
      setWithScroll(withScroll);
    }
  }, [blockId]);

  return (
    <div className="relative w-full max-w-full overflow-x-auto">
      {withScroll && (
        <div className="absolute w-4 h-full right-0 top-0 bg-gradient-to-r from-transparent to-white z-30"></div>
      )}
      <div className={clsx("relative overflow-x-auto", withScroll && "pr-4")} id={`matrixTableWrapper-${blockId}`}>
        <table className="border-separate">
          <thead>
            <tr>
              <th className="sticky text-center px-2 py-4 left-0"></th>
              {columns.map((column) => {
                if (hiddenCategories?.includes(column.id)) return null;
                return (
                  <th className="text-center px-2 py-4 relative font-normal text-xs text-gray-700" key={`${column.id}-${column.value}`}>
                    <div className={clsx("w-24", column.id === "unsorted" && "text-gray-700")}>{column.value}</div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody id="matrixTbody">
            {rows.map((row) => {
              if (!individual) {
                const sortedCardsAppearance = _.sortedUniq(
                  Object.keys(cardsData[row.id] ?? {})
                    .map((categoryKey) => cardsData[row.id][categoryKey])
                    .sort((a, b) => b - a)
                );
                const top1value = sortedCardsAppearance[0];
                const top2value = sortedCardsAppearance[1];
                const getCellColor = (cellValue: number) => {
                  if (cellValue === 0 || cellValue === undefined || individual) {
                    return "bg-gray-100";
                  }
                  if (cellValue === top1value) {
                    return "bg-green-200";
                  }
                  if (cellValue === top2value) {
                    return "bg-green-100";
                  }
                  return "bg-gray-100";
                };

                return (
                  <RenderIfVisible
                    key={row.id}
                    defaultHeight={24}
                    stayRendered={true}
                    rootElement="tr"
                    placeholderElement="td"
                  >
                    <td
                      className="sticky px-3 h-6 align-middle text-xs text-gray-700 left-0 bg-white text-ellipsis w-40 overflow-hidden"
                      style={{ minWidth: "160px" }}
                    >
                      <Tooltip
                        anchorClassName={!!row.description ? "cursor-pointer" : "cursor-default"}
                        content={<div className="w-48 text-xs">{row.description}</div>}
                        showOnlyIf={!!(row.description && row.description.length > 0)}
                      >
                        <span>{row.value}</span>
                      </Tooltip>
                    </td>
                    {columns.map((column) => {
                      // This happens when some responses were received before a new card was added
                      // We show this card at summary report but without percentage
                      const value = cardsData[row.id] ? cardsData[row.id][column.id] : 0;
                      const share = getShare(value, totalResponses!);
                      const percentage = (
                        <span className="font-medium text-green-800">{getShare(value, totalResponses!)}%</span>
                      );
                      if (hiddenCategories?.includes(column.id)) return null;
                      return (
                        <td
                          className={clsx("p-2 text-center min-w-5xs h-6 rounded-md", getCellColor(value))}
                          key={`${column.id}-${column.value}`}
                        >
                          {share && percentage}
                        </td>
                      );
                    })}
                  </RenderIfVisible>
                );
              } else {
                // This happens when some responses were received before a new card was added
                // We don't show this card at all for users who haven't sorted it
                if (!cardsData[row.id]) return null;

                return (
                  <tr key={row.id} className="h-6">
                    <td
                      className="sticky px-3 align-middle text-xs text-gray-700 left-0 bg-white text-ellipsis w-40 overflow-hidden"
                      style={{ minWidth: "160px" }}
                    >
                      <Tooltip
                        anchorClassName={!!row.description ? "cursor-pointer" : "cursor-default"}
                        content={<div className="w-48 text-xs">{row.description}</div>}
                        showOnlyIf={!!(row.description && row.description.length > 0)}
                      >
                        <span>{row.value}</span>
                      </Tooltip>
                    </td>
                    {columns.map((column) => {
                      const categoryKey = `${column.id}:${column.value}`;
                      return (
                        <td
                          className={clsx("p-2 text-center min-w-5xs h-6 text-xl rounded-md bg-gray-200")}
                          key={`${column.id}-${column.value}`}
                        >
                          {response!.sorting.find(
                            (sortItem) => 
                              `${sortItem.id}:${sortItem.value}` === categoryKey && 
                              sortItem.cards.find((card) => card.id === row.id)
                          )
                            ? "✓"
                            : ""}
                        </td>
                      );
                    })}
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MatrixTable;