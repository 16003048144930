import { useState, useCallback, useMemo, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ChatMessageRole } from '../../../Common/schema/Chats/ChatDTO';
import { ReportChatAPI } from '../../../actions/ReportChatAPI';
import { AIBlock, AssistantAiChatMessage, UserAiChatMessage } from '../../../models/Test';
import { useTags } from './useTags';

export function useChatReport(options: { testId?: string, block?: AIBlock, chatIdsFilter?: number[] }) {
	// state for currentChat
	const [currentChatId, setCurrentChatId] = useState<number | null>(null);
	const tags = useTags({ testId: options.testId || '', blockId: options.block?.blockId || '' });

	const chats = useQuery({
		queryKey: ['chats', options.testId, options.block?.blockId],
		queryFn: () => {
			return ReportChatAPI.getChats(options.testId as string, options.block?.blockId || '')
				.then(result => {
					// убираем последнее смс в чате от ассистента без ответа
					return result.map(chat => {
						const messages = chat.messages;
						if (messages && messages.length > 0 && messages[messages.length - 1].role === ChatMessageRole.Assistant) {
							messages.pop();
						}

						return chat;
					});
				});
		},
		enabled: !!options.testId && !!options.block?.blockId,
		refetchOnMount: false,
		refetchOnWindowFocus: false
	});


	const getTagsForMessage = useCallback((messageId: number) => {
		return tags.list.data?.filter(tag => tag.messages[messageId]) || [];
	}, [tags.list.data]);

	const taggedChats = useMemo(() => {

		let chatModels = chats.data?.map(chat => {
			return {
				...chat,
				createdAt: new Date(chat.createdAt),
				messages: chat.messages?.map(m => {
					const message = m.role === ChatMessageRole.Assistant ? new AssistantAiChatMessage(m.text) : new UserAiChatMessage(m.text);
					message.id = m.id;
					message.createdAt = new Date(m.createdAt);
					if (message.isUser) message.tags = getTagsForMessage(m.id);
					return message;
				})
			}
		});

		if (options.chatIdsFilter?.length) chatModels = chatModels?.filter(chat => options.chatIdsFilter?.includes(chat.id));

		return chatModels?.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
	}, [chats.data, getTagsForMessage, options.chatIdsFilter]);

	const currentChat = taggedChats?.find(chat => chat.id === currentChatId);

	useEffect(() => {
		if (taggedChats?.length && (!currentChatId || currentChatId && !taggedChats?.find(chat => chat.id === currentChatId))) {
			setCurrentChatId(taggedChats[0].id);
		}
	}, [taggedChats, setCurrentChatId]);


	return {
		chats,
		currentChat,
		currentChatId,
		setCurrentChatId,
		taggedChats
	};
}