import React, { useState, useEffect } from "react";

interface ISeekBarProps {
  seekBarValue: number;
  onChange: (newValue: number) => void;
}

export default function SeekBar(props: ISeekBarProps) {
  const progress = props.seekBarValue * 100;

  return (
    <input
      type="range"
      min="0"
      max="1"
      step="any"
      className="video-seek-bar w-full cursor-pointer video-replayer__seek-bar h-2"
      value={props.seekBarValue}
      onChange={(e) => props.onChange(parseFloat(e.target.value))}
      style={{
        background: `linear-gradient(to right, #0066FF 0%, #0066FF ${progress}%, #F0F0F0 ${progress}%, #F0F0F0 100%)`,
      }}
    />
  );
};