import { useState, useRef, useEffect } from 'react';

import { BlockPermissionSettings, PermissionType } from '../models/types';
import { FigmaBlockData } from './useFigmaHandlers';
import useHtmlPrototypeRecording from '../../../hooks/useHtmlPrototypeRecording';
import useNativePrototypeRecording from '../../../hooks/useNativePrototypeRecording';
import { StorageAPI } from '../../../../../../../actions/StorageAPI';
import { MIME_TYPES } from '../../../../../../../Common/constants';
import { VideoRecorder } from './VideoRecorder';
import { RecordResult, RecordTiming } from '../../../../../../Common/RecordResult';
import getMimeType from '../../../../../../../utils/getMimeType';

interface IUseRecordingOptions {
  blockId: string;
  answerId: string;
}

export default function useRecording(
  permissions: BlockPermissionSettings,
  screenStreamRef: React.MutableRefObject<MediaStream | null>,
  options: IUseRecordingOptions,
  prototypeLinkExists: boolean,
  data: FigmaBlockData
) {
  const { [PermissionType.CAMERA]: hasCameraAccess, [PermissionType.AUDIO]: hasAudioAccess, [PermissionType.SCREEN]: hasScreenVideoAccess } = permissions;

  const [isRecording, setIsRecording] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const faceVideoPlayerRef = useRef<HTMLVideoElement>(null);
  const faceRecorderRef = useRef<VideoRecorder | null>(null);
  const screenRecorderRef = useRef<VideoRecorder | null>(null);
  const [screenStream, setScreenStream] = useState<MediaStream | null>(null);

  const recordingsFileName = `${options.answerId}/${options.blockId}`;

  const canvasRecording = (prototypeLinkExists ? useNativePrototypeRecording : useHtmlPrototypeRecording)({
    blockId: options.blockId,
    answerId: options.answerId,
    setIsUploading: () => { },
  });

  const [isCanvasRecording, setIsCanvasRecording] = useState(!hasScreenVideoAccess && data.withVideo);
  const [isInitialScreenPermissionGranted, setIsInitialScreenPermissionGranted] = useState(false);
  const firstChange = useRef(true);

  // Add a ref to store the camera stream
  const cameraStreamRef = useRef<MediaStream | null>(null);

  useEffect(() => {
    setIsCanvasRecording(!hasScreenVideoAccess && data.withVideo);

    if (firstChange.current) {
      firstChange.current = false;
      setIsInitialScreenPermissionGranted(true);
    }

    if (!hasScreenVideoAccess && isInitialScreenPermissionGranted && screenStreamRef.current) {
      stopMediaStream(screenStreamRef.current!);
    }
  }, [hasScreenVideoAccess]);

  function stopMediaStream(stream: MediaStream) {
    stream.getTracks().forEach((track) => track.stop());
  }

  useEffect(() => {
    if (faceVideoPlayerRef?.current) {
      navigator.mediaDevices.getUserMedia({ video: hasCameraAccess, audio: hasAudioAccess })
        .then((stream) => {
          if (faceVideoPlayerRef.current) {
            faceVideoPlayerRef.current.srcObject = stream;
            cameraStreamRef.current = stream; // Store the stream reference
          }
        })
        .catch((error) => {
          console.error("Error accessing camera: ", error);
        });
    }

    // Stop green light on screen stream
    if (!hasCameraAccess && cameraStreamRef.current) {
      stopMediaStream(cameraStreamRef.current);
    }

    // Disabled because on mac Safari the stream rerendered and the camera stream was stopped
    // Cleanup function to stop the camera stream
    // return () => {
    //   if (cameraStreamRef.current) {
    //     console.log('RETURN stopMediaStream cameraStreamRef.current', cameraStreamRef.current);
    //     stopMediaStream(cameraStreamRef.current);
    //     cameraStreamRef.current = null;
    //   }
    // };
  }, [hasCameraAccess, hasAudioAccess, faceVideoPlayerRef.current]);


  // Initialize the screen recorder when stream is available
  useEffect(() => {
    if (hasScreenVideoAccess && screenStream && !screenRecorderRef.current) {
      try {
        const mimeType = getMimeType(hasScreenVideoAccess);

        const videoRecorder = new VideoRecorder(screenStream, 15, { mimeType });
        screenRecorderRef.current = videoRecorder;
        videoRecorder.start();
        console.log('Screen recorder set and started', videoRecorder);
      } catch (error) {
        console.error('Error initializing screen recorder', error);
      }
    }

    return () => {
      if (screenStream) {
        stopMediaStream(screenStream);
      }
    }
  }, [hasScreenVideoAccess, screenStream]);

  function start() {
    if ((hasAudioAccess || hasCameraAccess) && faceVideoPlayerRef.current && faceVideoPlayerRef.current.srcObject) {
      const newRespondentStream = faceVideoPlayerRef.current!.srcObject as MediaStream;
      startFaceRecording(newRespondentStream);
      setIsRecording(true);
    }
    if (hasScreenVideoAccess && screenStreamRef.current) {
      setScreenStream(screenStreamRef.current);
      setIsRecording(true);
    }
    if (isCanvasRecording) {
      canvasRecording.start();
    }
  }

  function startFaceRecording(respondentStream: MediaStream) {
    if (!faceRecorderRef.current) {
      try {
        const mimeType = getMimeType(hasCameraAccess);

        const videoRecorder = new VideoRecorder(
          respondentStream,
          15,
          { mimeType, videoBitsPerSecond: 300000 }
        );
        faceRecorderRef.current = videoRecorder;
        videoRecorder.start();
        console.log('Face recorder set and started', videoRecorder);
      } catch (error) {
        console.error('Error initializing face recorder', error);
      }
    }
  }

  async function finish() {
    const recordResult = new RecordResult();
    setIsUploading(true);

    try {
      // Останавливаем записи сразу, чтобы они заканчивались одновременно
      if (faceRecorderRef.current) {
        faceRecorderRef.current.stop();
      }

      if (screenRecorderRef.current) {
        screenRecorderRef.current.stop();
      }

      if (hasCameraAccess && faceVideoPlayerRef.current && faceVideoPlayerRef.current.srcObject) {
        const stream = faceVideoPlayerRef.current.srcObject as MediaStream;
        stopMediaStream(stream);
        faceVideoPlayerRef.current.srcObject = null;
      }
    } catch (error) {
      console.warn('Error while stopping recordings', error);
      if (hasCameraAccess) {
        recordResult.cameraErrorReason = error instanceof Error ? error.message : String(error);
      }
      if (hasAudioAccess) {
        recordResult.audioErrorReason = error instanceof Error ? error.message : String(error);
      }
      if (hasScreenVideoAccess) {
        recordResult.screenErrorReason = error instanceof Error ? error.message : String(error);
      }
    }

    if (isCanvasRecording) {
      try {
        await canvasRecording.finish();
        recordResult.canvasRecord = true;
      } catch (error) {
        recordResult.canvasErrorReason = error instanceof Error ? error.message : String(error);
      }
    }

    if (faceRecorderRef.current) {
      try {
        const blobType = hasCameraAccess ? MIME_TYPES.VIDEO_MP4 : MIME_TYPES.AUDIO_MP4;
        let blob = await faceRecorderRef.current.getVideoBlob(blobType);

        if (blob.size === 0) {
          throw new Error('No data to save');
        }

        const respondentRecordingFileName = `${recordingsFileName}-respondent.mp4`;
        await StorageAPI.uploadFile(respondentRecordingFileName, blob, blobType, respondentRecordingFileName, { path: 'figmarecordings', prefix: 'none' });

        const recordTiming = new RecordTiming(faceRecorderRef.current.startTS, faceRecorderRef.current.stopTS);

        if (hasCameraAccess) {
          recordResult.cameraRecord = recordTiming;
        }

        if (hasAudioAccess) {
          recordResult.audioRecord = recordTiming;
        }

        if (cameraStreamRef.current) {
          stopMediaStream(cameraStreamRef.current);
        }

      } catch (error) {
        if (hasCameraAccess) {
          recordResult.cameraErrorReason = error instanceof Error ? error.message : String(error);
        }
        if (hasAudioAccess) {
          recordResult.audioErrorReason = error instanceof Error ? error.message : String(error);
        }
      }
    }

    if (screenRecorderRef.current) {
      try {
        const screenBlob = await screenRecorderRef.current.getVideoBlob();

        if (screenBlob.size === 0) {
          throw new Error('No data to save');
        }

        if (screenStream) {
          stopMediaStream(screenStream);
        }

        const screenRecordingFileName = `${recordingsFileName}-screen.mp4`;
        await StorageAPI.uploadFile(screenRecordingFileName, screenBlob, MIME_TYPES.VIDEO_MP4, screenRecordingFileName, { path: 'figmarecordings', prefix: 'none' });
        recordResult.screenRecord = new RecordTiming(screenRecorderRef.current.startTS, screenRecorderRef.current.stopTS);
      } catch (error) {
        recordResult.screenErrorReason = error instanceof Error ? error.message : String(error);
        recordResult.screenRecord = null;
      }
    }

    console.log('finish, recordResult', recordResult);
    setIsUploading(false);
    return recordResult;
  }

  function recordEvent(event: string, data: Record<string, any>) {
    if (isCanvasRecording) {
      canvasRecording.recordEvent(event, data);
    }
  }

  return {
    isRecording,
    videoFaceRef: faceVideoPlayerRef,
    isUploading,
    start,
    finish,
    recordEvent
  };
};