import { useState, useEffect, useRef } from "react";

interface IVideoOffsets {
  startOffset: number;
  duration: number;
}

interface IUseVideoControlsProps {
  mainVideoRef: React.RefObject<HTMLVideoElement>;
  additionalVideoRef?: React.RefObject<HTMLVideoElement>;
  videoOffsets: IVideoOffsets;
  duration: number;
}

export default function useVideoControls({ mainVideoRef, additionalVideoRef, videoOffsets, duration }: IUseVideoControlsProps) {
  const [seekBarValue, setSeekBarValue] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const animationFrameRef = useRef<number>();

  // флаг, чтобы не воспроизводить screen video, если он уже закончился сам
  const isEnded = useRef(false);

  useEffect(() => {
    if (!mainVideoRef.current) return;

    const videoElement = mainVideoRef.current;

    function updateValue () {
      if (videoElement.duration === Infinity) {
        setSeekBarValue(videoElement.currentTime / duration);
      } else {
        setSeekBarValue(videoElement.currentTime / videoElement.duration);
      }
    };

    videoElement.addEventListener('timeupdate', updateValue);
    return () => videoElement.removeEventListener('timeupdate', updateValue);
  }, [mainVideoRef]);


  useEffect(() => {
    if (!mainVideoRef.current) return;

    const videoElement = mainVideoRef.current;

    // Start sync when video plays
    function handlePlay() {
      setIsPlaying(true)
      isEnded.current = false;
      if(additionalVideoRef?.current) animationFrameRef.current = requestAnimationFrame(syncVideos);
    };

    // Stop sync when video pauses
    function handlePause() {
      setIsPlaying(false)
      isEnded.current = false;

      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      if (additionalVideoRef?.current) additionalVideoRef?.current.pause();
    };

    videoElement.addEventListener('play', handlePlay);
    videoElement.addEventListener('pause', handlePause);
    additionalVideoRef?.current?.addEventListener('ended', () => isEnded.current = true);

    return () => {
      videoElement.removeEventListener('play', handlePlay);
      videoElement.removeEventListener('pause', handlePause);
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [mainVideoRef.current, additionalVideoRef?.current]);

  useEffect(() => {
    function handleSpaceKey(e: KeyboardEvent) {
      if (e.key === ' ') togglePlayPause();
    };

    window.addEventListener('keypress', handleSpaceKey);

    return () => window.removeEventListener('keypress', handleSpaceKey);
  }, []);

  function togglePlayPause() {
    const videoElement = mainVideoRef.current;

    if (videoElement && document.contains(videoElement)) {
      if (videoElement.paused) {
        videoElement.play().catch(error => {
          console.error("Error attempting to play video:", error);
        });
      } else {
        videoElement.pause();
      }
    }
  };

  function changeSeekBarValue(newValue: number) {
    const mainVideoElement = mainVideoRef.current;

    if (!mainVideoElement) return;
  
    let currentDuration = mainVideoElement.duration;
  
    if (!isFinite(currentDuration) || isNaN(newValue)) {
      currentDuration = duration;
      console.error("Invalid duration or seek bar value:", { currentDuration, newValue });
    }
  
    const newTime = currentDuration * newValue;
    mainVideoElement.currentTime = newTime;
    setSeekBarValue(newValue);
  
    if (!isPlaying) togglePlayPause();
  }

  function syncVideos() {
    if (!additionalVideoRef?.current || !mainVideoRef.current || !videoOffsets) return;

    const screenTime = mainVideoRef.current.currentTime;
    const adjustedTime = screenTime - videoOffsets.startOffset;

    if (screenTime > videoOffsets.startOffset) {

      if (screenTime > videoOffsets.duration + videoOffsets.startOffset) {
        // console.log('pause screen video: current time is more than duration + offset');
        additionalVideoRef.current.pause();
      } else {

        const timeDiff = Math.abs(additionalVideoRef.current.currentTime - adjustedTime);
      
        // console.log('play screen video: current time is less than duration + offset');
        // проверяем флаг, чтобы не воспроизводить screen video, если он уже закончился сам
        if (!mainVideoRef.current.paused && additionalVideoRef.current.paused && !isEnded.current) {
          additionalVideoRef.current.currentTime = adjustedTime;
          additionalVideoRef.current.play()
            .catch(error => console.error('Error starting screen video:', error));
        } else if (timeDiff > 0.3) { // Resync if videos drift more than 300ms
          additionalVideoRef.current.currentTime = adjustedTime;
        }
      }
    } else {
      // console.log('pause screen video: current time is less than offset');
      if (additionalVideoRef.current.currentTime > 0) additionalVideoRef.current.currentTime = 0;
      if (!additionalVideoRef.current.paused) additionalVideoRef.current.pause();
    }

    // Schedule next sync
    animationFrameRef.current = requestAnimationFrame(syncVideos);
  }


  return { seekBarValue, changeSeekBarValue, isPlaying, togglePlayPause };
} 