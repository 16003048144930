import React, { useState, useEffect } from "react";

import { IconPause, IconPlay } from "../../../../../icons";

type PlayPauseButtonProps = {
  isPlaying: boolean;
  togglePlayPause: () => void;
}

export default function PlayPauseButton(props: PlayPauseButtonProps) {

  return (
    <button onClick={props.togglePlayPause}>
      {props.isPlaying ? <IconPause className="fill-current text-white hover:opacity-75 transition-opacity" /> : <IconPlay className="fill-current text-white hover:opacity-75 transition-opacity" />}
    </button>
  );
};
