import React from "react";

import NodeInHtml from "../../Figma/NodeInHtml";
import { IReportScreenState } from './FigmaReport';
import { IFormattedPrototype } from '../../../utils/figma';
import { FigmaDisplayMode } from '../../Figma/NodeInHtml/NodeInHtmlUtils';

export interface IPreviewProps {
  prototype: IFormattedPrototype | undefined;
  screen: IReportScreenState;
  width: number;
  onClick: () => void;
  showScreenName?: boolean;
}

const Preview = ({ showScreenName = true, ...props }: IPreviewProps) => {
  const style = { width: props.width, height: props.width };
  const nodeKey = props.screen.nodeKey.split('-')[0];
  const node = props.prototype?.nodesForHtml[nodeKey];

  if (node && props.prototype?.settings) {
    return (
      <div className="prototype-preview flex flex-col">
        <div
          className="prototype-preview__frame flex flex-nowrap rounded-lg shadow-xs mr-4 overflow-hidden cursor-pointer transition-all duration-75 ease-in border-2 border-gray-300 hover:border-blue-600 box-content"
          style={style}
          onClick={props.onClick}
        >
          <div
            className='prototype-preview__frame__scaling'
            style={{
              transformOrigin: "0 0",
              transform: `scale(${props.width / node.width})`,
            }}
          >
            <NodeInHtml
              display={FigmaDisplayMode.actualSize}
              imageScale={props.prototype.settings.imageScale}
              node={node}
              prototype={props.prototype}
              overlayActions={props.screen.overlays}
            />
          </div>
        </div>
        {showScreenName && (
          node.name && <div className="prototype-preview__name w-[72px] block mt-2 text-gray-700 text-xs break-words">{node.name}</div>
        )}
      </div>
    );
  }

  return (
    <div className="prototype-preview flex flex-col flex-nowrap">
      <div
        className="prototype-preview__frame overflow-hidden mr-2 rounded-md cursor-pointer transition-all duration-75 ease-in border-2 border-transparent border-gray-300 hover:border-blue-600 shadow-xs"
        style={style}
        onClick={props.onClick}
      >
        <img src={props.screen?.image} alt={props.screen?.name} />
      </div>
      {showScreenName && (
        <div className="prototype-preview__name w-[72px] block mt-2 text-gray-700 text-xs break-words" style={{ width: style.width }}>
          {props.screen?.name}
        </div>
      )}
    </div>
  );
};

export default Preview;
