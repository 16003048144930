import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { AIBlock } from '../../../models/Test';
import { IAiChatResponse } from '../../../models/Response';

import { MessageTag } from './Chat/MessageTag';
import { AiChatTags } from './ReportSections/AiChatTags';
import { AIChatMessages } from './ReportSections/AIChatMessages';
import { ListWithSeparators } from '../../Common/Containers/ListWithSeparators';
import { IMessageTag } from '../../../Common/schema/Chats/UpdateChatMessageDTO';
import ReportBlockHeader, { ReportHeaderMedia } from '../ReportBlockHeader';
import TextDropdown from '../../TextDropdown';

import { TestBlockReportContext } from '../ReportContext';

import { useChatReport } from './useChatReport';
import { useTags } from './useTags';

function AllConversationsItem() {
	const { t } = useTranslation();
	return <span className='py-[2px]'>{t("Show all conversations")}</span>;
}

interface AiChatReportSummaryProps {
	responses: IAiChatResponse[];
}

export default function AiChatReportSummary(props: AiChatReportSummaryProps) {
	const reportContext = useContext(TestBlockReportContext);
	const { t } = useTranslation();
	const testId = reportContext?.testId || '';
	const block = reportContext?.block as AIBlock;

	const { taggedChats } = useChatReport({ testId, block: block, chatIdsFilter: props.responses.map(r => r.chatId) });
	const tags = useTags({ testId, blockId: block?.blockId || '' });

	const [filterTags, setFilterTags] = useState<Record<number, boolean>>({});
	const hasFilterTags = Object.keys(filterTags).length > 0;

	const defaultItem = { name: <AllConversationsItem />, value: 'all' };
	const filterDropdownItems = [
    defaultItem,
		...(tags.list.data?.map(tag => ({ value: tag.id.toString(), name: <MessageTag colorClassName={tags.getTagStyle(tag.color)}>{tag.name}</MessageTag> })) || [])
	];

	const chatsWithFilteredMessages = taggedChats?.map(chat => {
		const messages = chat.messages?.filter((currentMessage, i) => {
			const isCurrentHasTag = currentMessage.tags?.some(t => filterTags[t.id]);
			const isNextHasTag = !!chat.messages?.[i + 1]?.tags?.some(t => filterTags[t.id]);
			return isCurrentHasTag || isNextHasTag;
		});

		return { ...chat, messages };
	}).filter(chat => !!chat.messages?.length);

	useEffect(() => {
		if (tags.list.data) {
			// remove tags that are not in the list from filterTags
			const existentTags = tags.list.data.reduce((acc, tag) => {
				acc[tag.id] = tag;;
				return acc;
			}, {} as Record<number, IMessageTag>);
			setFilterTags(filterTags => _.pickBy(filterTags, (value, key) => !!existentTags[+key]));
		}
	}, [tags.list.data])

	function onTagSelect(tag: IMessageTag) {
		setFilterTags(filterTags => ({ ...filterTags, [tag.id]: true }));
	}

	function onTagRemove(tag: IMessageTag) {
		setFilterTags(filterTags => _.omit(filterTags, tag.id));
	}

	return (
    <div className="ai-chat-report ai-chat-report_mode_summary flex flex-col gap-4">
      <div className='flex flex-col'>
        <div className="ai-instruction__header caption">{t("Question")}</div>
        <ReportBlockHeader questionText={block.text}>
					<ReportHeaderMedia image={block.image} video={block.video} />
				</ReportBlockHeader>
      </div>
      {/* <AiInstruction className="mb-6" instruction={block?.instruction || ""} /> */}

      <AiChatTags filterTags={{}} onTagSelect={onTagSelect} chats={taggedChats} />

      {/* {hasFilterTags && <AiChatFilterTags filterTags={filterTags} onTagRemove={onTagRemove} />} */}

      <div className="ai-chat-report__chats grid grid-cols-3 sm:grid-cols-5 gap-x-6 grid-rows-[auto_1fr]">
        <div className="ai-chat-report__chats-header col-start-1 col-span-3 sm:col-span-5 h-auto mb-4 self-start">
            <TextDropdown
              className="w-fit-content"
              items={filterDropdownItems}
              value={Object.keys(filterTags).length > 0 
                ? Object.keys(filterTags).map((id) => id.toString())[0]
                : 'all'}
              onChange={(value) =>
                value !== 'all'
                  ? setFilterTags({ [(tags.list.data?.find((t) => t.id === +value))!.id]: true })
                  : setFilterTags({})
              }
            />
        </div>
        {!hasFilterTags && (
          <div className="max-h-full col-start-1 col-span-3 sm:col-span-5">
            {taggedChats?.map((chat, i) => (
              <div
                key={chat.id}
                className={clsx("chat", { "mt-6": i > 0 })}
              >
                <AIChatMessages
                  messages={chat.messages || []}
                  showScrollPadding={false}
                  initialQuestion={block?.text}
                />
              </div>
            ))}
          </div>
        )}

        {/* Old layout with chats column on the left and current chat on the right */}
        {/* {!hasFilterTags && <div className='col-start-1 col-span-1'>
				<div className='ai-chat-report__chats-list flex flex-col gap-2 max-h-full overflow-auto'>
					{taggedChats?.map(chat => <AiChatListItem key={chat.id} chat={chat as any} isSelected={chat.id === currentChatId} onClick={() => setCurrentChatId(chat.id)} />)}
					<ScrollFade />
				</div>
			</div>}
			{!hasFilterTags && !!currentChat && <AIChatMessages className='col-start-2 col-span-2 sm:col-span-4 max-h-full' key={currentChat.id} messages={currentChat.messages || []} />} */}

        {hasFilterTags && (
          <div className="col-start-1 col-span-3 sm:col-span-5 max-h-full self-stretch">
            <ListWithSeparators indent={4}>
              {chatsWithFilteredMessages?.map((chat) => (
                <AIChatMessages key={chat.id} messages={chat.messages || []} showDate showScrollPadding={false} />
              ))}
            </ListWithSeparators>
            <ScrollFade />
          </div>
        )}
      </div>
    </div>
  );
}

function ScrollFade() {
	return <div className="sticky bottom-0 min-h-6 bg-gradient-to-t from-current text-white pointer-events-none"></div>;
}