import React from "react";

import { DeviceType } from "../../models/Report";
import { IDeviceInfo } from "./utils/getDeviceInfo";
import { IconMobileDevice, IconLaptop } from "../../icons";

interface IDeviceInfoProps {
  deviceInfo?: IDeviceInfo;
  showWindowSize?: boolean;
}

export function DeviceInfo({ deviceInfo, showWindowSize }: IDeviceInfoProps) {
  if (!deviceInfo) return null;

  const deviceIcon = deviceInfo.deviceType === DeviceType.mobile ? (
    <IconMobileDevice className="text-gray-600 fill-current w-4 h-4 flex-shrink-0" />
  ) : (
    <IconLaptop className="text-gray-600 w-4 h-4 flex-shrink-0" />
  );

  return (
    <div className="device-info flex items-center gap-1 overflow-hidden max-w-[300px]">
      {deviceInfo.deviceType && <span className="device-info__icon">{deviceIcon}</span>}
      {showWindowSize && deviceInfo.viewportSize && <InfoLabel label={deviceInfo.viewportSize} />}
      <InfoLabel label={deviceInfo.deviceOS} />
      <InfoLabel label={deviceInfo.browser} />
    </div>
  );
}

function InfoLabel(props: { label: string | null }) {
  const { label } = props;
  
  if (!label) return null;
  
  return (
    <>
      <p className="device-info__separator text-gray-600 text-xs flex-shrink-0">&middot;</p>
      <p className="device-info__label text-gray-600 text-xs overflow-hidden text-ellipsis whitespace-nowrap flex-shrink-0">
        {label}
      </p>
    </>
  );
}