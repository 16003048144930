import { t } from "i18next";
import React from "react";

import { IconMonitorDisabled, IconStop } from "../../../../../icons";

interface ErrorVideoMessageProps {
  type?: 'notFound' | 'notAvailable';
}

export default function ErrorVideoMessage(props: ErrorVideoMessageProps) {

  if (props.type === 'notFound') {
    return (
      <div className='flex justify-center items-center w-full h-full flex-col gap-2'>
        <IconStop className="w-8 h-8 fill-current text-gray-600" />
        <p className='text-gray-600'>{t("Video not found")}</p>
      </div>
    );
  }

  return (
    <div className='flex justify-center items-center w-full h-full flex-col gap-2'>
      <IconMonitorDisabled />
      <p className='text-gray-600'>{t("Screen recording is not available")}</p>
    </div>
  );
} 