import React from "react";
import AppContext, { IAppContext } from "../../../AppContext";
import lodash from "lodash";

import FigmaHtml from "./FigmaHtml";
import { ClickmapStorage, actionTypes, CLICKMAP_STORAGE_KEY, ClickmapCollection, IClickmap } from './STORAGE';
import { IPosition } from './Models';
import { IFormattedPrototype } from '../../../utils/figma';
import { IReportScreenState } from '../FigmaReport/FigmaReport';
import { IArea } from "../models";

export { default as FigmaHtmlClickmap } from "./FigmaHtmlClickmap";
export { default as Heatmap } from "./Heatmap";
export { default as Clicks } from "./Clicks";
export { default as FigmaClicks } from "./FigmaClicks";
export { default as FigmaHeatmap } from "./FigmaHeatmap";

export interface IFigmaHtmlLoadedParams {
  width: number;
  height: number;
  nodePositions: Record<string, IPosition>;
}

const initialState = {
  loading: true,
  figmaNodePositions: {} as Record<string, IPosition>
};

export interface IClickmapContextProps {
  imageUrl: string;
  /** @deprecated From old version of Figma support */
  nodeForHtml?: string;
  prototype?: IFormattedPrototype;
  screen?: IReportScreenState;
  clickmapOwnerId: string;

  children: (clickmap: IClickmap,
    updateClickmapAreas: (areas: IArea[]) => void,
    figmaImage: IFigmaImage | undefined,
    isLoading: boolean,
    onLoad: (params: any) => void) => React.ReactNode;
}

export interface IFigmaImage {
  nodePositions: Record<string, IPosition>;
  component: React.ReactNode;
}

export class ClickmapContext extends React.Component<IClickmapContextProps, typeof initialState> {
  imageWidth: any;
  imageHeight: any;
  clickmap: any;
  mount: boolean | undefined;

  constructor(props: IClickmapContextProps) {
    super(props);
    this.state = {
      ...initialState,
    };
  }

  componentDidMount() {
    this.mount = true;
    if (!this.props.nodeForHtml) {
      this.initializeAreas();
    }
  }

  componentDidUpdate(prevProps: IClickmapContextProps) {
    if (
      prevProps.imageUrl !== this.props.imageUrl ||
      prevProps.clickmapOwnerId !== this.props.clickmapOwnerId ||
      !lodash.isEqual(prevProps.nodeForHtml, this.props.nodeForHtml)
    ) {
      this.setState({ ...initialState });
      this.clickmap = undefined as ClickmapCollection | undefined;
      this.imageWidth = undefined;
      this.imageHeight = undefined;
      if (!this.props.nodeForHtml) {
        this.initializeAreas();
      }
    }
  }

  componentWillUnmount() {
    this.mount = false;
  }

  async initializeAreas() {
    const context = this.context as IAppContext;

    const clickmap = ClickmapStorage.getClickmap(this.props.clickmapOwnerId);

    // parse image width and height
    const imageMeta = await this.getImageMeta(this.props.imageUrl);

    this.imageWidth = imageMeta.width;
    this.imageHeight = imageMeta.height;

    if (clickmap) {
      // check that the image has not changed
      if (clickmap.width === this.imageWidth &&
        clickmap.height === this.imageHeight) {
        context.dispatch({ type: actionTypes.GET, payload: clickmap as any }); // Save data to context
        this.clickmap = clickmap;
      } else {
        // delete the clickmap if the image has changed
        ClickmapStorage.deleteClickmap(this.props.clickmapOwnerId);
      }
    }

    if (!this.mount) return;
    this.setState({ loading: false });
  }

  initializeAreasForFigmaHtml(figmaHtmlParams: IFigmaHtmlLoadedParams) {
    if (this.imageWidth || this.imageHeight) return;

    const { clickmapOwnerId } = this.props;
    const context = this.context as IAppContext;

    const clickmap = ClickmapStorage.getClickmap(clickmapOwnerId);

    this.imageWidth = figmaHtmlParams.width;
    this.imageHeight = figmaHtmlParams.height;
    // this.setState({ figmaNodePositions: figmaHtmlParams.nodePositions });

    if (clickmap) {
      // check that the image has not changed
      if (
        clickmap.width === this.imageWidth &&
        clickmap.height === this.imageHeight
      ) {
        context.dispatch<IClickmap>({
          type: actionTypes.GET,
          payload: clickmap as any,
        }); // Save data to context
        this.clickmap = clickmap;
      } else {
        // delete the clickmap if the image has changed
        ClickmapStorage.deleteClickmap(clickmapOwnerId);
      }
    }

    if (!this.mount) return;
    this.setState({ loading: false });
  }

  getImageMeta(URL: string) {
    return new Promise<{ width: number; height: number }>((resolve) => {
      const image = new Image();
      image.src = URL;
      image.onload = () =>
        resolve({
          width: image.width,
          height: image.height,
        });
    });
  }

  getClickmapDefaultParams() {
    return {
      areas: [],
      width: this.imageWidth,
      height: this.imageHeight,
      ownerId: this.props.clickmapOwnerId,
    } as IClickmap;
  }

  getClickmap(): IClickmap {
    const { clickmapOwnerId } = this.props;
    const { state } = this.context as IAppContext;

    return lodash.get(state, [CLICKMAP_STORAGE_KEY, clickmapOwnerId], this.getClickmapDefaultParams());
  }

  updateClickmapAreas = async (areas: IArea[]) => {
    const updatedClickmap = this.clickmap
      ? { ...this.clickmap, areas }
      : { ...this.getClickmapDefaultParams(), areas };

    this.clickmap = updatedClickmap;
    (this.context as IAppContext).dispatch({
      type: actionTypes.GET,
      payload: updatedClickmap,
    });

    ClickmapStorage.updateClickmap(this.props.clickmapOwnerId, updatedClickmap);
  };

  render() {
    let figmaImage = undefined as IFigmaImage | undefined;

    if (this.props.nodeForHtml) {
      figmaImage = {
        nodePositions: this.state.figmaNodePositions,
        component: (
          <FigmaHtml
            nodeForHtml={this.props.nodeForHtml}
            onLoad={(params: IFigmaHtmlLoadedParams) => {
              this.initializeAreasForFigmaHtml(params);
            }}
          />
        ),
      };
    }

    if (this.props.prototype && this.props.screen?.nodeKey) {
      figmaImage = {
        nodePositions: this.state.figmaNodePositions,
        component: undefined,
      };
    }

    return <>
      {this.props.children(
        this.getClickmap(),
        this.updateClickmapAreas,
        figmaImage,
        this.state.loading,
        this.initializeAreasForFigmaHtml.bind(this))
      }
    </>

    // return (
    //   <WrappedComponent
    //     clickmap={this.getClickmap()}
    //     updateClickmapAreas={this.updateClickmapAreas}
    //     figmaImage={figmaImage}
    //     loading={this.state.loading}
    //     // TODO remove V
    //     {...this.props}
    //     clickmapOwnerId={this.props.clickmapOwnerId}
    //     imageUrl={this.props.imageUrl}
    //     nodeForHtml={this.props.nodeForHtml}
    //     prototype={this.props.prototype}
    //     screen={this.props.screen}
    //   />
    // );
  }
}

ClickmapContext.contextType = AppContext;