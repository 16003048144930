import React, { useState, PropsWithChildren } from "react";
import { useFloating, offset, flip, shift, useInteractions, useClick, useDismiss, size } from "@floating-ui/react";
import clsx from "clsx";
import "../tailwind.generated.css";

export type DropdownOption = {
  name: string | JSX.Element;
  value: string;
  disabled?: boolean;
};

export type Divider = "divider";

type DropdownProps = {
  type: "single" | "none";
  handler: (value: string) => void;
  items: Array<DropdownOption | Divider>;
  header?: string;
  value?: string;
  light?: boolean;
  parentTestId?: string;
};

function Dropdown(props: PropsWithChildren<DropdownProps>) {
  const [isOpen, setIsOpen] = useState(false);
  const [_, setSelectedOptions] = useState(props.value);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'bottom-start',
    middleware: [
      offset(4),
      flip(),
      shift(),
      size({
        apply({ rects, elements }) {
          const bottom = rects.reference.y + rects.reference.height;
          const maxHeight = window.innerHeight - bottom - 20; // 20px for some padding
          Object.assign(elements.floating.style, {
            minWidth: `${rects.reference.width}px`,
            maxHeight: `${maxHeight}px`,
            overflowY: 'auto', 
          });
        },
      }),
    ],
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context),
  ]);

  const isEmpty = props.items.length === 0;

  const handleSelect = (itemValue: string) => {
    console.log("handleSelectitemValue", itemValue);

    if (props.type === "none") {
      props.handler(itemValue);
      setIsOpen(false);
    }

    if (props.type === "single") {
      props.handler(itemValue);
      setSelectedOptions(itemValue);
      setIsOpen(false);

      console.log("selectedOptions", _);
      console.log("itemValue", itemValue);
    }
  };

  return (
    <div className="dropdown">
      <div
        ref={refs.setReference}
        {...getReferenceProps()}
        className="dropdown__trigger"
      >
        {props.children}
      </div>
      {isOpen && (
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
          className="dropdown__menu z-[999] rounded-lg p-2 mt-1 bg-white shadow-xl border-1 border-gray-400 text-gray-800 no-scrollbar"
        >
          <div className={clsx("dropdown__menu-inner")}>
            {!!props.header && props.header}
            {props.items.map((item, i, items) => {
              if (item === "divider") {
                if (i === 0 || i === items.length - 1 || items[i - 1] === "divider" || items[i + 1] === "divider") {
                  return null;
                }
                return <div key={`divider-${i}`} className="w-full bg-gray-200 my-2 h-px" />;
              }
              return (
                <div
                  key={item.value}
                  data-value={item.value}
                  data-testid={props.parentTestId && `${props.parentTestId}.item`}
                  className={`flex items-center cursor-pointer rounded-md py-1 px-2 w-full text-gray-800 shadow-none hover:bg-gray-200 transition-all duration-75 ease-in`}
                  onClick={(e) => {
                    if (!item.disabled) {
                      e.preventDefault();
                      e.stopPropagation();
                      handleSelect(item.value);
                    }
                  }}
                >
                  <div
                    className={`dropdown__item cursor-pointer rounded-md w-full whitespace-nowrap overflow-hidden text-ellipsis grow-default shrink-default min-w-16 max-w-[400px] ${item.disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}`}
                    title={typeof item.name === 'string' ? item.name : ''}
                  >
                    {item.name}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default Dropdown;
