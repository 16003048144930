import React from 'react';
import clsx from 'clsx';

const alphabet = 'abcdefghijklmnopqrstuvwxyz';

interface IAlphabetBulletProps {
  optionIndex: number;
  className?: string;
}

export default function AlphabetBullet(props: IAlphabetBulletProps) {
  const { optionIndex, className } = props;

  return (
    <div 
      className={clsx(
        "alphabet-bullet",
        "w-6 h-6 rounded-md bg-gray-300 flex items-center justify-center text-gray-700 font-bold text-sm capitalize",
        className
      )}
    >
      {alphabet[optionIndex]}
    </div>
  );
}
