import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import "../../../tailwind.generated.css";

import Modal from "../../Modal";

import { ReactComponent as IconZoom } from "../../../icons/zoom.svg";
import ReportBlockHeader from "../ReportBlockHeader";

import { IPreferencePairwiseComparisonResponse, IPreferenceResponse, IPreferenceSingleComparisonResponse } from "../../../models/Response";
import { IPreferenceOptionReply } from "../../../models/Test";
import SingleReport from "./Single";
import PairwiseReport from "./Pairwise";

interface IPreferenceReportProps{
  comparisonType: "single" | "pairwise";
  replies: IPreferenceOptionReply[];
  responses: IPreferenceResponse[];
  questionText: string;
}

export default function PreferenceReport(props: IPreferenceReportProps) {

  if (!props.comparisonType || props.comparisonType === "single") {
    return <SingleReport replies={props.replies} responses={props.responses as IPreferenceSingleComparisonResponse[]} questionText={props.questionText} />;
  }

  return <PairwiseReport replies={props.replies} responses={props.responses as IPreferencePairwiseComparisonResponse[]} questionText={props.questionText} />;
};
