import { VideoMimeType } from "@vidstack/react";
import { MediaType } from "../../models/Test";

export const alphabet = "abcdefghijklmnopqrstuvwxyz".split("");

export const validImageTypes = ["image/png", "image/jpeg", "image/gif", "image/svg+xml", "image/webp"];
export const validVideoTypes = ["video/mp4", "video/webm", "video/quicktime"];
export const supportedVideoPlayerTypes: VideoMimeType[] = ["video/mp4", "video/webm"];
export const validFileSize = {
	image: 15 * 1024 * 1024, // 15MB
	video: 25 * 1024 * 1024, // 25MB
};
export const maxDimension = 16000;

export const mediaValidators = {
  [MediaType.image]: {
    size: (file: File) => {
      if (file.size > validFileSize.image) {
        return `The image size should be up to 15MB.`;
      }
      return null;
    },
    fileType: validImageTypes,
  },
  [MediaType.video]: {
    size: (file: File) => {
      if (file.size > validFileSize.video) {
        return `The video size should be up to 25MB.`;
      }
      return null;
    },
    fileType: validVideoTypes,
  },
};