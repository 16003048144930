import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from "react-i18next";
import clsx from 'clsx';
import _ from 'lodash';
import { Scrollbars } from "react-custom-scrollbars-2";

import FigmaIFrame from '../../Figma/FigmaIFrame'
import { WithScroll } from '../../Figma/WithScroll';
import { Heatmap } from '../Clickmaps';
import { FigmaReportSidebar } from './FigmaReportSidebar';
import Clickarea from '../Clickareas/Clickarea';
import FigmaClickareaData from '../Clickareas/FigmaClickareaData';
import Loader from '../../Loader';

import useFigmaContext from '../../Figma/hooks/useFigmaContext';
import { tabsLabels } from '../hooks/useReportScreenTabs';
import useClickareas from '../Clickareas/hooks/useClickareas';
import { getHeatmapMaxWeight } from '../Clickmaps/Utils';

import { IReportPrototypeClick } from './FigmaReport'
import { IPrototypeNode, IPrototypeOptions } from '../../Figma/Models';
import { CanvasSize } from '../../../models/Response';


enum DisplayMode {
	IMAGE = 'image',
	CLICKS = 'clicks',
	HEATMAP = 'heatmap'
}

export default function NativePrototypeScreenReport(props: {
	currentScreenId: string,
	clicks: IReportPrototypeClick[],
	figmaScreenStructure?: IPrototypeNode,
	renderData: any,
	prototypeOptions: IPrototypeOptions,
	onScreenChanged: (screenId: string) => void;
	showTabs: boolean;
	activeTab: string;
	canShowClicksOrder: boolean;
	canShowFirstClickControls: boolean;
	screenStats?: any[];
	size?: CanvasSize;
}) {
	const prototypeScreenSize = props.figmaScreenStructure?.absoluteBoundingBox as { width: number, height: number } | undefined;
	const protoWidth = prototypeScreenSize?.width || 0;
	const protoHeight = prototypeScreenSize?.height || 0;
	const activeTab = props.activeTab;

	const [showOnlyFirstClicks, setShowOnlyFirstClicks] = useState(false);
	const [showClicksOrder, setShowClicksOrder] = useState(false);
	const [reportDisplayMode, setReportDisplayMode] = useState<DisplayMode | null>(null);
	const [clickAreaMode, setClickAreaMode] = useState(false);
	const { t } = useTranslation();

	const figmaContext = useFigmaContext();
	const screenClicks = (showOnlyFirstClicks ? _.uniqBy(props.clicks.filter(c => c.number === 1), click => click.answerId) : props.clicks)
		.filter(click => click.clickData?.raw?.presentedNodeId === props.currentScreenId);

	const maxHeatmapWeight = getHeatmapMaxWeight(screenClicks, 1, 1);

	// prototype scaling
	const scaledRef = useRef<HTMLDivElement>(null);
	const scaledParentRef = useRef<HTMLDivElement>(null);
	const wrapperRef = useRef<HTMLDivElement>(null);
	const parentRef = useRef<HTMLDivElement>(null);
	const scrollRef = useRef<Scrollbars>(null);
	const [scaleRatio, setScaleRatio] = useState(1);

	// ref for clickmap
	const clickmapRef = useRef<HTMLDivElement>(null);
	const { areas, clickAreaRef, removeArea } = useClickareas(scaleRatio, clickmapRef, props.currentScreenId, clickAreaMode, showOnlyFirstClicks, props.screenStats?.[0][1] || 0 as number);
	const [isLoading, setIsLoading] = useState(true);
	const [startNodeId] = useState(props.currentScreenId);

	useEffect(() => {
		figmaContext?.onPrototypeLoaded(onProtoLoad);
		window.addEventListener('resize', scalePrototypeToFitParent);
		scalePrototypeToFitParent();

		return () => {
			figmaContext?.offPrototypeLoaded(onProtoLoad);
			window.removeEventListener('resize', scalePrototypeToFitParent);
		};
	}, []);

	useEffect(() => {
		scalePrototypeToFitParent();
		scrollRef.current?.scrollToTop();
	}, [prototypeScreenSize]);

	function onProtoLoad() {
		setIsLoading(false);
	}

	function scalePrototypeToFitParent() {
		const scaled = scaledRef.current;
		const scaledParent = scaledParentRef.current;
		const wrapper = wrapperRef.current;
		const parent = parentRef.current;

		if (!parent || !scaled || !scaledParent || !wrapper) return;

		// Скрываем элемент, чтобы получить размеры родительского элемента
		wrapper.style.display = 'none';

		const parentWidth = parent.clientWidth;
		const parentHeight = parent.clientHeight;

		// Показываем элемент снова, чтобы его можно было масштабировать
		wrapper.style.display = 'block';

		// Рассчитываем коэффициенты масштабирования для ширины
		const widthRatio = parentWidth / protoWidth;

		// Выбираем минимальный коэффициент масштабирования, чтобы элемент полностью вписывался в родительский элемент
		const scaleRatio = Math.min(widthRatio, 1);

		// Устанавливаем исходные размеры элемента
		scaled.style.width = protoWidth + 'px';
		scaled.style.height = protoHeight + 'px';

		// Применяем масштабирование
		scaled.style.transform = `scale(${scaleRatio})`;
		scaled.style.transformOrigin = '0 0';

		// Масштабируем родителя, чтобы не было лишнего скролла
		scaledParent.style.width = parentWidth + 'px';
		scaledParent.style.height = (protoHeight * scaleRatio) + 'px';

		// Устанавливаем высоту у основной обёртки
		wrapper.style.height = parentHeight + 'px';

		// Запоминаем масштаб
		setScaleRatio(scaleRatio);
	}

	useEffect(() => {
		// показываем/скрываем хитмапы и клики в зависимости от вкладки
		if (!props.showTabs) {
			setReportDisplayMode(DisplayMode.CLICKS);
			return;
		}
		if (activeTab === tabsLabels.CLICKS) {
			setReportDisplayMode(DisplayMode.CLICKS);
		} else if (activeTab === tabsLabels.HEATMAP) {
			setReportDisplayMode(DisplayMode.HEATMAP);
		} else {
			setReportDisplayMode(DisplayMode.IMAGE);
		}
	}, [activeTab]);

	function onPresentedNodeChanged(data: any) {
		props.onScreenChanged(data?.presentedNodeId);
	}

	return <>
		<div className='prototype-screen-report flex flex-row gap-6 items-start justify-between w-full h-full'>
			{/* Здесь релатив позиционирование нужно чтобы накладывать кликмапы на фрейм */}
			<div className={clsx('relative w-full h-full bg-black rounded-md', isLoading ? "overflow-hidden" : "overflow-auto")} ref={parentRef}>
				<div className={clsx("absolute inset-0 flex items-center justify-center bg-black z-10", !isLoading && "hidden")}>
					<div className="text-white font-medium text-center">
						<Loader />
						<div className="mt-4 text-base font-normal text-gray-500">Loading...</div>
					</div>
				</div>
				{!props.figmaScreenStructure && <div className='unknown-screen-message w-full h-full flex flex-col items-center justify-center'>
					<div className='unknown-screen-message__title font-semibold'>
						{t('Uknown screen')}
					</div>
					<div className='unknown-screen-message__subtitle'>
						{t('Probably prototype have been changed after import')}
					</div>
				</div>}
				<div ref={wrapperRef}>
					<Scrollbars ref={scrollRef}>
						<div className="overflow-hidden" ref={scaledParentRef}>
							<div className="prototype-screen-report__wrapper relative m-auto my-0" ref={scaledRef}>
								<FigmaIFrame
									className={clsx(activeTab === tabsLabels.HEATMAP && "brightness-75")}
									options={{
										...props.prototypeOptions,
										scaling: 'scale-down-width',
										watchscroll: true,
										startNodeId,
									}}
									onPresentedNodeChanged={onPresentedNodeChanged}
									width={`${protoWidth}px`}
									height={`${protoHeight}px`}
								/>
								<div
									ref={clickmapRef}
									className={`clickmap absolute overflow-hidden inset-0 bg-white bg-opacity-25 ${clickAreaMode ? 'pointer-events-auto cursor-[crosshair]' : 'pointer-events-none'}`}
								>
									{props.figmaScreenStructure && <ScreenLayer
										key={props.figmaScreenStructure.id}
										baseCoordinates={props.figmaScreenStructure.absoluteBoundingBox}
										layer={props.figmaScreenStructure}
										prototypeSize={{ width: protoWidth, height: protoHeight }}
										renderData={props.renderData}
										clicks={screenClicks}
										displayMode={reportDisplayMode}
										showClicksOrder={showClicksOrder}
										freezeScroll={clickAreaMode}
										maxHeatmapWeight={maxHeatmapWeight}
										// height={testerProtoHeight}
										isRoot
									/>}
									<div className="clickareas absolute inset-0" ref={clickAreaRef}>
										{areas.map((area, index) => {
											return <Clickarea key={'area-' + index} scaleRatio={scaleRatio} area={area} onClick={removeArea}>
												<FigmaClickareaData area={area}/>
											</Clickarea>
										})}
									</div>
								</div>
							</div>
						</div>
					</Scrollbars>
				</div>
			</div>
			<div className="prototype-screen-report__sidebar min-w-[230px] max-w-[230px] h-full overflow-auto">
				<FigmaReportSidebar
					showFirstClickControls={!isLoading && props.canShowFirstClickControls}
					showClicksOrderControls={!isLoading && props.canShowClicksOrder}
					showClickAreas={false}
					screenStats={props.screenStats || []}
					onAreaAdded={(a: any) => { }}
					setShowOnlyFirstClicks={setShowOnlyFirstClicks}
					showOnlyFirstClicks={showOnlyFirstClicks}
					setShowClicksOrder={setShowClicksOrder}
					showClicksOrder={showClicksOrder}
					showClickAreaMode={!props.canShowClicksOrder}
					clickAreaModeOn={clickAreaMode}
					setClickAreaModeOn={setClickAreaMode}
				/>
			</div>
		</div>
	</>
}

interface IScreenClick extends IReportPrototypeClick {
	fixedOwnerId?: string;
	newOwnerId?: string;
	hasCorrectedPosition?: boolean;
}

function adjusCoordinates(originalCoordinates: { x: number, y: number }, adjustment: { x: number, y: number }) {
	return { x: originalCoordinates.x - adjustment.x, y: originalCoordinates.y - adjustment.y };
}

export function ScreenLayer(props: {
	/** базовые абсолютные координаты экрана прототипа на канвасе */
	baseCoordinates: { x: number, y: number },
	parentCoordinates?: { x: number, y: number },
	prototypeSize?: { width: number, height: number },
	renderData: any,
	layer: IPrototypeNode,
	clicks: IReportPrototypeClick[];
	displayMode?: DisplayMode | null;
	showClicksOrder: boolean;
	isRoot?: boolean;
	freezeScroll?: boolean;
	height?: number;
	isFixed?: boolean;
	maxHeatmapWeight: number;
}) {

	const layerRef = useRef<HTMLDivElement>(null);
	const scrollAreaSizeRef = useRef<{ size: { width: number, height: number } | null }>({ size: null });
	const [scrollableAreaSize, setScrollableAreaSize] = useState<{ width: number | null, height: number | null }>(scrollAreaSizeRef.current?.size || { width: null, height: null });
	// state for canDrawHeatmap
	const [canDrawHeatmap, setCanDrawHeatmap] = useState(false);
	const figmaContext = useFigmaContext();

	//#region Layer size stuff and scroll
	const layerSize = props.layer.absoluteBoundingBox as { width: number, height: number };
	const layerCoordinates = props.layer.absoluteBoundingBox as { x: number, y: number, width: number, height: number };
	// вычисляем координаты для нода в html на основе абслютных координат отностиельно канваса фигмы
	const adjustedCoordinates = adjusCoordinates(layerCoordinates, props.baseCoordinates);
	// запоминаем координаты для фиксированных слоев, чтобы потом найти клики внутри них
	const fixedLayerCoordinates = Object.assign({}, adjustedCoordinates);

	if (props.isFixed && props.parentCoordinates) {
		// добавляем координаты родителя, т.к. они не учитываются в абсолютных координатах
		adjustedCoordinates.x += props.parentCoordinates.x;
		adjustedCoordinates.y += props.parentCoordinates.y;

		// если размер фрейма прототипа больше чем он сейчас отображается, то мы подстраиваем текущий фикс. элемент и делаем ему координаты такие же, как щас в фигме
		if (props.renderData && props.renderData.nodesRenderData[props.layer.id]) {
			const layerRenderData = props.renderData.nodesRenderData[props.layer.id];

			// console.log('ADJUSTING', layerRenderData, props.renderData, props.prototypeSize, adjustedCoordinates);
			if (props.prototypeSize?.height && props.prototypeSize.height < (adjustedCoordinates.y + props.layer.absoluteBoundingBox.height)) {
				const rootScreenRenderData = props.renderData.nodesRenderData[props.renderData.presentedNodeId];
				const x = layerRenderData.nodeCanvasBounds.x - rootScreenRenderData.absoluteBounds.x;
				const y = layerRenderData.nodeCanvasBounds.y - rootScreenRenderData.absoluteBounds.y;
				adjustedCoordinates.y = y;
				adjustedCoordinates.x = x;
			}
		}
	}

	const [scrollLeft, setScrollLeft] = useState(0);
	const [scrollTop, setScrollTop] = useState(0);

	const layerStyleHeight = props.height || layerSize.height;
	const layerStyle = {
		width: (props.isRoot && props.prototypeSize ? props.prototypeSize.width : layerSize.width) + 'px',
		height: (props.isRoot && props.prototypeSize ? props.prototypeSize.height : layerStyleHeight) + 'px',
		top: adjustedCoordinates.y + 'px',
		left: adjustedCoordinates.x + 'px'
	};

	// используем контекст для синхронизации скролла с прототипом для каждого такого нода
	figmaContext?.onScroll(props.layer.id, (eventData) => {
		setScrollLeft(eventData.scrollPosition.x);
		setScrollTop(eventData.scrollPosition.y);
		// if (layerRef.current) {
		// 	layerRef.current.style.left = (adjustedCoordinates.x - eventData.scrollPosition.x) + 'px';
		// 	layerRef.current.style.top = (adjustedCoordinates.y - eventData.scrollPosition.y) + 'px';
		// }
		// обновляем размеры скроллабельной области, т.к. с событием нам приходят реальные размеры
		// console.log('setScrollableAreaSize', eventData.scrollPosition);`
		if (scrollableAreaSize.height == null ||
			scrollableAreaSize.width == null ||
			eventData.scrollPosition.width > scrollableAreaSize.width ||
			eventData.scrollPosition.height > scrollableAreaSize.height ||
			layerStyleHeight + eventData.scrollPosition.y > scrollableAreaSize.height ||
			layerSize.width + eventData.scrollPosition.x > scrollableAreaSize.width
		) {
			const size = {
				width: Math.max(eventData.scrollPosition.width, layerSize.width + eventData.scrollPosition.x),
				height: Math.max(eventData.scrollPosition.height, layerStyleHeight + eventData.scrollPosition.y)
			};
			scrollAreaSizeRef.current.size = size;
			setScrollableAreaSize(size);
		}
	});

	//#endregion

	const [clicks, setClicks] = useState<IScreenClick[]>([]);
	const [scrollableArea, setScrollableArea] = useState<{ maxX: number, maxY: number, minX: number, minY: number }>({ maxX: layerSize.width, maxY: layerSize.height, minX: 0, minY: 0 });
	const [fixedChildrenData, setFixedChildrenData] = useState<{ coords: { x: number, y: number }, clicks: IScreenClick[] }[]>([]);
	const heatmapClicks = clicks;
	useEffect(() => {
		const clicks = [] as IScreenClick[];
		const clicksToCorrect = [] as IScreenClick[];
		let maxX = layerSize.width;
		let maxY = layerSize.height;
		let minX = 0;
		let minY = 0;

		const fixedChildrenData = (props.layer.fixedChildren?.map((child) => {
			return {
				coords: adjusCoordinates(child.absoluteBoundingBox, layerCoordinates),
				clicks: []
			}
		}) || []) as { coords: { x: number, y: number }, clicks: IScreenClick[] }[];

		if (props.isFixed) {
			// Если текущий слой - это фиксированный элемент, то ищем только клики внутри него
			for (let i = 0; i < props.clicks.length; i++) {
				const click = props.clicks[i];
				clicks.push(click);
			}
			if (!canDrawHeatmap) setCanDrawHeatmap(true);
		}
		// Иначе если скроллящийся слой, то берем клики которые принадлежат ему или все, если нет детей
		else {
			// loop through clicks and find the max x and y and min x and y and filter clicks by layer id
			for (let i = 0; i < props.clicks.length; i++) {
				const click = props.clicks[i];

				if (click.clickData.nodeId === props.layer.id ||
					props.layer.fixedChildren?.length ||
					(props.isRoot && !props.layer.children?.length)
				) {
					const clickCopy = _.cloneDeep(click) as IScreenClick;

					// наличие координат - признак того, что это клик может быть внутри фиксированного элемента
					// if (clickCopy.clickData.raw?.nearestScrollingFrameMousePosition) {
					// ищем клики внутри фиксированных элементов
					fixedChildrenData.forEach((childData, i) => {
						const fixedChild = props.layer.fixedChildren[i];
						if (clickCopy.fixedOwnerId === fixedChild.id) {
							childData.clicks.push(clickCopy);
						}
					});
					// }

					// если клик не принадлежит фиксированному элементу, то добавляем его в массив кликов
					if (!clickCopy.fixedOwnerId && clickCopy.newOwnerId === props.layer.id) clicks.push(clickCopy);

					// Дополнительная ветка для Старых прототипов, у которых нет информации о дочерних фиксированных детях или просто детях
					// Если текущий слой – такой, то мы берём все клики которые ему не принадлежат и добавляем на него
					// console.log('LAYER', props.layer, clickCopy, !props.layer.fixedChildren?.length && !!clickCopy.fixedOwnerId, clickCopy.newOwnerId !== props.layer.id && !props.layer.children?.length)
					if (props.isRoot && !props.layer.fixedChildren?.length &&
						(
							!!clickCopy.fixedOwnerId ||
							clickCopy.newOwnerId !== props.layer.id && !props.layer.children?.length
						)) {
						clickCopy.fixedOwnerId = '';
						clicks.push(clickCopy);
					}

					if (clickCopy.clickData.nodeId !== props.layer.id) clicksToCorrect.push(clickCopy);

					maxX = Math.max(maxX, clickCopy.clickData.left);
					maxY = Math.max(maxY, clickCopy.clickData.top);
					minX = Math.min(minX, clickCopy.clickData.left);
					minY = Math.min(minY, clickCopy.clickData.top);
				}
			}
		}

		if (!scrollAreaSizeRef.current.size) setScrollableArea({ maxX, maxY, minX, minY });
		setClicks(clicks);
		setFixedChildrenData(fixedChildrenData);
		// console.log('clicks', clicks, clicksToCorrect);

	}, [props.clicks, props.isFixed]);


	// пока не узнали реальный размер области, то умножаем тупо на 2, чтобы область была больше и скроллилась
	const coeff = props.layer.overflowDirection ? 2 : 1;
	const scrollableAreaHeight = scrollableAreaSize.height || (scrollableArea.maxY * coeff);
	const scrollableAreaWidth = scrollableAreaSize.width || (scrollableArea.maxX * coeff);

	// если скролла у слоя нет, то устанавливаем размеры, чтобы отрисовался хитмап
	useEffect(() => {
		if (!props.layer.overflowDirection || scrollableArea.maxX <= layerSize.width && scrollableArea.maxY <= layerSize.height) {
			setScrollableAreaSize({ width: scrollableArea.maxX, height: scrollableArea.maxY });
		}
	}, [props.layer.overflowDirection, scrollableArea]);

	// if (props.isFixed) {
	// 	console.log('fixed height', scrollableAreaSize.height, clicks.filter(click => props.isFixed || !((click as any).fixedOwner)))
	// }

	return <div
		key={props.layer.id}
		id={props.layer.id}
		className={clsx('screen-layer bg-black bg-opacity-[5%]', {
			'root': props.isRoot,
			'fixed': props.isFixed,
			'absolute': !props.isFixed
		}, props.layer.clipsContent || ((scrollableAreaSize.height || 0) > layerStyleHeight || (scrollableAreaSize.width || 0) > layerSize.width))}
		style={layerStyle}
		ref={layerRef}
	>
		<WithScroll scrollTop={scrollTop} scrollLeft={scrollLeft}>
			<div className="screen-layer__data relative" style={{ width: `${scrollableAreaWidth}px`, height: `${scrollableAreaHeight}px` }}>

				{!props.isFixed && <div className={`screen-layer__clicks w-full h-full absolute ${props.displayMode === DisplayMode.CLICKS ? 'visible' : 'invisible'}`}>
					{clicks.map((click, index) => {
						if (click.fixedOwnerId) return null;
						return <Click key={'click-' + index} click={click} showNumber={props.showClicksOrder} />
					})}
				</div>}
				{!!props.isFixed && <div className={`screen-layer__clicks w-full h-full absolute ${props.displayMode === DisplayMode.CLICKS ? 'visible' : 'invisible'}`}>
					{clicks.map((click, index) => {
						return <Click key={'click-' + index} click={click} showNumber={props.showClicksOrder} left={(click.clickData.left || 0)} top={(click.clickData.top || 0)} />
					})}
				</div>}

				{/* 
				не отрисовываем хитмапы, пока не придет событие скролла, после которого мы узнаем реальный размер
				иначе хитмапы отрисуются с неверным размером и их потом очень геморно перерисовывать. 
				Событие скрола приходит после инициализации прототипа
				*/}
				{!props.isFixed && scrollableAreaSize.height !== null && <Heatmap
					className={`screen-layer__heatmap ${props.displayMode === DisplayMode.HEATMAP ? 'visible' : 'invisible'}`}
					width={scrollableAreaWidth}
					height={scrollableAreaHeight}
					clicks={heatmapClicks}
					maxWeight={props.maxHeatmapWeight}
					useAbsoluteValues
				/>}

				{props.isFixed && canDrawHeatmap && <Heatmap
					className={`screen-layer__heatmap ${props.displayMode === DisplayMode.HEATMAP ? 'visible' : 'invisible'}`}
					width={scrollableAreaWidth}
					height={scrollableAreaHeight}
					maxWeight={props.maxHeatmapWeight}
					clicks={heatmapClicks}
					useAbsoluteValues
				/>}

			</div>
			{props.layer.children?.filter(c => c.scrollBehavior !== "FIXED")?.map((child) => {
				return <ScreenLayer key={child.id}
					baseCoordinates={layerCoordinates}
					renderData={props.renderData}
					layer={child}
					clicks={props.clicks}
					maxHeatmapWeight={props.maxHeatmapWeight}
					displayMode={props.displayMode} showClicksOrder={props.showClicksOrder} freezeScroll={props.freezeScroll} />
			})}
	</WithScroll>
	
		{fixedChildrenData?.map((data, i) => {
			const child = props.layer.fixedChildren[i];
			return <ScreenLayer key={child.id}
				renderData={props.renderData}
				baseCoordinates={layerCoordinates}
				parentCoordinates={adjustedCoordinates}
				prototypeSize={props.prototypeSize}
				layer={child}
				clicks={data.clicks}
				maxHeatmapWeight={props.maxHeatmapWeight}
				isFixed={true}
				displayMode={props.displayMode} showClicksOrder={props.showClicksOrder} freezeScroll={props.freezeScroll} />
		})}

	</div>
}

export function Click(props: { click: IReportPrototypeClick, showNumber: boolean, top?: number, left?: number }) {
	const clickStyle = {
		top: (props.top || props.click.clickData.top) + 'px',
		left: (props.left || props.click.clickData.left) + 'px'
	}

	return <div
		className={`click border-2 -translate-x-2 -translate-y-2 absolute w-4 h-4 rounded-full
		${props.click.handled ? 'bg-green-500 border-green-700' : 'bg-red-500 border-red-700'} 
		flex items-center justify-center text-xs`}
		data-handled={props.click.handled}
		data-response-id={props.click.answerId}
		data-click={JSON.stringify(props.click)}
		style={clickStyle}>{props.showNumber ? props.click.number : ''}</div>
}