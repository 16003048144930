import clsx from "clsx";
import React from "react";

export interface ISummaryStatsProps {
	stats: (string | number | React.ReactElement)[][];
	className?: string;
}

export function SummaryStats({ stats, className }: ISummaryStatsProps) {
	return (
		<div className={clsx("figma-screen-stats flex text-gray-800 gap-16", className)}>
			{stats.map(([name, value, hint], index) => (
				<div key={index} className="flex flex-col gap-1">
											<span className="figma-screen-stats__name flex items-center captionNoMargin">
							{name}
						</span>
					<span className="figma-screen-stats__value font-semibold text-xl">{value}</span>
						{/* {!!hint && <TooltipWithIcon className="ml-1 inline text-gray-600" size={20} text={hint} />} */}
				</div>
			))}
		</div>
	);
}
