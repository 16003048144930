import React, { useState, useRef } from "react";
import clsx from "clsx";
import "../tailwind.generated.css";

import {
	useFloating,
	autoUpdate,
	offset,
	flip,
	shift,
	useHover,
	useFocus,
	useDismiss,
	useRole,
	useInteractions,
	FloatingPortal,
	FloatingArrow,
	arrow,
} from "@floating-ui/react";

type TooltipProps = {
	content: string | JSX.Element;
	anchorClassName?: string;
	children: JSX.Element;
	showOnlyIf?: boolean;
	position?: "top" | "bottom" | "left" | "right" | "left-start" | "left-end" | "top-start" | "top-end" | "bottom-start" | "bottom-end" | "right-start" | "right-end";
};

const Tooltip = ({
	content,
	anchorClassName,
	children,
	showOnlyIf = true,
	position
}: TooltipProps) => {
	const [isVisible, setIsVisible] = useState(false);
	const arrowRef = useRef(null);

	const { x, y, strategy, refs, context } = useFloating({
		placement: position || "top",
		open: isVisible,
		onOpenChange: (openState) => {
			if (showOnlyIf) {
				setIsVisible(openState);
			}
		},
		middleware: [
			offset(10),
			arrow({ element: arrowRef }),
		],
		whileElementsMounted: autoUpdate,
	});

	const hover = useHover(context, { move: false });
	const focus = useFocus(context);
	const dismiss = useDismiss(context);
	const role = useRole(context, { role: "tooltip" });

	const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role]);

	return (
		<div
			className={clsx("cursor-pointer", anchorClassName && anchorClassName)}
			ref={refs.setReference}
			{...getReferenceProps()}
		>
			{children}
			{isVisible && showOnlyIf && (
				<FloatingPortal>
					<div
						ref={refs.setFloating}
						style={{
							position: strategy,
							top: y ?? 0,
							left: x ?? 0,
							width: "max-content",
							zIndex: 9999,
						}}
						{...getFloatingProps()}
						className="bg-gray-900 text-white rounded-md p-2 text-base tooltip z-999"
					>
						<FloatingArrow ref={arrowRef} context={context} tipRadius={4} />
						{content}
					</div>
				</FloatingPortal>
			)}
		</div>
	);
};

export default Tooltip;
