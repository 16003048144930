import React, { useContext, useEffect } from "react";
import "../tailwind.generated.css";
import clsx from "clsx";
import { IconError, IconInfo, IconCheck } from "../icons";

import { motion, AnimatePresence } from "framer-motion";

import Loader from "./Loader";

import appContext from "../AppContext";

import { setNotification } from "../actions";

const Toast = () => {
  const { state, dispatch } = useContext(appContext);

  const text = state.app.notification.text;
  const type = state.app.notification.type;

  const iconMap = {
    error: IconError,
    info: IconInfo,
    success: IconCheck,
  }

  let IconComponent: any = null;

  if (type) {
    IconComponent = iconMap[type as keyof typeof iconMap];
  };

  function handleUpdateNotification() {
    if (type !== 'loading') {
      setTimeout(() => {
        dispatch(setNotification(null, null));
      }, 2500);
    }
  }

  useEffect(() => {
    handleUpdateNotification();
  }, [text, type]);

  return (
    <AnimatePresence>
      {type && (
        <motion.div
          initial={{ opacity: 0, y: -15 }}
          animate={{ opacity: 1, y: 0, transform: "revert-layer" }}
          exit={{ opacity: 0, y: 15 }}
          transition={{ type: "spring", duration: 0.5, bounce: 0.6 }}
          className={clsx(
            "fixed bottom-8 rounded-full px-4 py-2 font-medium flex items-center left-[50%] -translate-x-1/2 justify-center z-[9990]",
            type === "success" && "bg-green-200 text-green-900",
            type === "info" && "bg-black text-white",
            type === "error" && "bg-red-600 text-white",
            type === "warning" && "bg-orange-600 text-white",
            type === "loading" && "bg-gray-800 opacity-90 h-12 text-white"
          )}
        >
          {type === "success" && IconComponent && (
            <IconComponent className="fill-current text-green-900 mr-2" width={20} height={20} />
          )}
          {type === "loading" && <Loader />}
          {text && text}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Toast;
