import React, { useState, useEffect } from "react";

type TimeDisplayProps = {
  videoRef: React.RefObject<HTMLVideoElement>;
};

export default function TimeDisplay(props: TimeDisplayProps) {
  const [currentTime, setCurrentTime] = useState(0);

  const duration = props.videoRef.current?.duration;

  useEffect(() => {
    const updateTime = () => {
      if (!props.videoRef.current) return;
      setCurrentTime(props.videoRef.current.currentTime);
    };

    props.videoRef.current!.addEventListener('timeupdate', updateTime);
    return () => props.videoRef.current?.removeEventListener('timeupdate', updateTime);
  }, [props.videoRef]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <div className='video-timer text-xs text-center text-white font-bold w-[40px]'>
      {formatTime(currentTime)}
    </div>
  );
};