import { IDeviceInfo } from "../../components/Report/utils/getDeviceInfo";

export enum PanelAnswerStatus {
  screenedOut = "screenedOut",
  quotaFull = "quotaFull",
  completed = "completed",
  disqualified = "disqualified",
}

export interface IUrlTags {
  [key: string]: string;
}

export interface IAnswerMetaData {
  source: "link" | "toloka" | "customPanel";
  urlTags?: IUrlTags;
  panelRespondentStatus?: PanelAnswerStatus;
  userAgent?: string; 
  deviceInfo: IDeviceInfo;  
}
