import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from 'clsx';

import "../../../tailwind.generated.css";


import { PreferenceBlock } from "../../../models/Test";
import { IPreferenceResponse, isPreferencePairwiseComparison, isPreferenceSingleComparison } from "../../../models/Response";

import AlphabetBullet from "../../TestEditor/AlphabetBullet";

import ImageWithZoom from "../ImageWithZoom";

interface IPreferenceOptionDisplayProps {
    reply: { image: string, replyValue: string } | undefined;
    optionIndex: number;
    withCaption?: boolean;
    withImage?: boolean;
  }

function PreferenceOptionDisplay({ reply, optionIndex, withCaption = true, withImage = true }: IPreferenceOptionDisplayProps) {
    if (!reply) return null;
  
    return (
      <div className="preference-option flex items-center gap-2">
        <AlphabetBullet optionIndex={optionIndex} />
        {!!withImage && reply.image && (
            <ImageWithZoom
              imageSrc={reply.image}
              className="rounded-md w-8 h-8 object-contain bg-white border-gray-250 border-1"
            />
        )}
        {!!withCaption && <span className="preference-option__text font-medium">{reply.replyValue}</span>}
      </div>
    );
  }

interface IPreferenceIndividualReportProps {
  blockData: PreferenceBlock;
  blockResponse: IPreferenceResponse;
}

export default function PreferenceIndividualReport({ blockData, blockResponse }: IPreferenceIndividualReportProps) {
    const { t } = useTranslation();

    if (isPreferencePairwiseComparison(blockResponse)) {
        const replies = blockData.replies;
        
        const getOptionData = (optionId: string) => ({
            reply: replies.find(r => r.id === optionId),
            index: replies.findIndex(r => r.id === optionId)
        });

        const tableHeaders = [t("Option 1"), t("Option 2"), t("Winner")].map(header => (
            <th key={header} className="preference-individual-response__th py-2 px-3 text-left font-medium border-b-1 border-r-1 border-gray-200 captionNoMargin">
                {t(header)}
            </th>
        ));

        return (
            <div className="preference-individual-response">
                <table className="preference-individual-response__table w-full mt-4 border-1 border-gray-250 rounded-lg border-separate border-spacing-0 shadow-xxs">
                    <thead className="preference-individual-response__header">
                        <tr>{tableHeaders}</tr>
                    </thead>
                    <tbody>
                        {blockResponse.selectedOptions.map((option, index, array) => {
                            const option1 = getOptionData(option.option1);
                            const option2 = getOptionData(option.option2);
                            const winner = getOptionData(option.winner);
                            const isLastRow = index === array.length - 1;

                            return (
                                <tr key={option.pairId}>
                                    <td className={clsx(
                                        'preference-individual-response__cell',
                                        'p-4 border-r-1 border-gray-200',
                                        { 'border-b-1': !isLastRow }
                                    )}>
                                        <PreferenceOptionDisplay 
                                            reply={option1.reply} 
                                            optionIndex={option1.index}
                                        />
                                    </td>
                                    <td className={clsx(
                                        'preference-individual-response__cell',
                                        'p-4 border-r-1 border-gray-200',
                                        { 'border-b-1': !isLastRow }
                                    )}>
                                        <PreferenceOptionDisplay 
                                            reply={option2.reply} 
                                            optionIndex={option2.index}
                                        />
                                    </td>
                                    <td className={clsx(
                                        'preference-individual-response__cell',
                                        'p-4 border-gray-200',
                                        { 'border-b-1': !isLastRow }
                                    )}>
                                        <PreferenceOptionDisplay 
                                            reply={winner.reply} 
                                            optionIndex={winner.index}
                                            withCaption={false}
                                            withImage={false}
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
  if (isPreferenceSingleComparison(blockResponse)) {
    const selectedOptionsMap = blockResponse.selectedOptions
      .map((selectedOption: any) => ({
        option: selectedOption,
        reply: blockData.replies.find((r: any) => r.id === selectedOption.id),
      }))
      .filter((o: any) => !!o.reply);

    return (
      <div className="preference-individual-response mt-4">
        {selectedOptionsMap.map((optionMap: any) => (
          <div className="preference-individual-response__item flex items-center justify-start" key={optionMap.option.id}>
            <ImageWithZoom
              imageSrc={optionMap?.reply?.image}
              className="rounded-md w-12 h-12 object-contain bg-white border-gray-250 border-1"
            />
            {!!optionMap?.reply?.replyValue && <span className="preference-individual-response__text ml-4 font-medium">{optionMap?.reply?.replyValue}</span>}
          </div>
        ))}
      </div>
    );
  }
  return null;
}
