import React, { useState } from "react";
import PropTypes from "prop-types";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

import Preview from "./Preview";
import Tabs, { TabItem } from "../../Tabs";
import { loaderColorMap } from "../../Button";
import Modal from "../../Modal";
import { PrototypeType } from "./FigmaReport";

import { IconTick, IconCancel, IconStop, IconCirclePlay } from "../../../icons";
import { IReportState, IReportStateByTester } from './FigmaReport';
import { CanvasSize } from '../../../models/Response';
import Loader from "../../Loader";
import VideoPlayerResolver from "./Player/VideoPlayerResolver";

import { IFormattedPrototype } from '../../../utils/figma';
import { determineVideoStatus } from "./utils/determineVideoStatus";
import { VideoStatus } from "./utils/determineVideoStatus";
import useKeyDownHandler from "./hooks/useKeyDownHandler";
import isAnswerHasVideo from "./utils/isAnswerHasVideo";

export interface IFigmaReportViewProps {
  blockId: string;
  byScreen: IReportState["byScreen"];
  byTester: IReportState["byTester"];
  onPreviewClick: (path: (string | number)[]) => void;
  prototype: IFormattedPrototype;
  sharingToken?: string;
  isSummaryReport: boolean;
}

export default function FigmaPrototypeScreensReport(props: IFigmaReportViewProps) {
  const { t } = useTranslation();
  return (
    <div className='figma-screens-report flex flex-col gap-3'>
      {props.isSummaryReport && <div className="figma-screens-report__summary flex flex-col gap-1">
        <div className="figma-screens-report__summary-header flex gap-2">
          <div className="figma-screens-report__summary-content flex flex-col gap-1">
            <div className="figma-screens-report__summary-title text-gray-800 text-base+ font-medium">{t("Heatmaps and clicks")}</div>
            <span className="figma-screens-report__summary-description text-gray-600 text-sm block">{t("See heatmaps and clicks for each screen or individual responses")}</span>
          </div>
        </div>
      </div>}
      {props.isSummaryReport ? (
        <Tabs>
          <TabItem label={t("By Screen")}>
            <FigmaReportByScreen
              byScreen={props.byScreen}
              onPreviewClick={props.onPreviewClick}
              prototype={props.prototype} />
          </TabItem>
          <TabItem label={t("By Tester")}>
            <FigmaReportByTester
              blockId={props.blockId}
              byTester={props.byTester}
              onPreviewClick={props.onPreviewClick}
              prototype={props.prototype}
              sharingToken={props.sharingToken}
              isSummaryReport={props.isSummaryReport}
            />
          </TabItem>
        </Tabs>
      ) : (
        <TabItem label={t("By Tester")}>
          <FigmaReportByTester
            blockId={props.blockId}
            byTester={props.byTester}
            onPreviewClick={props.onPreviewClick}
            prototype={props.prototype}
            sharingToken={props.sharingToken}
            isSummaryReport={props.isSummaryReport}
          />
        </TabItem>
      )}
    </div>
  );
}

function FigmaReportByTester(props: Omit<IFigmaReportViewProps, 'byScreen'>) {
  const isReportHasVideo = props.byTester.some(isAnswerHasVideo);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [selectedTester, setSelectedTester] = useState(props.byTester[0]);

  useKeyDownHandler(props.isSummaryReport, props.byTester, selectedTester, setSelectedTester, () => setIsVideoModalOpen(false));

  return (
    <div className="figma-bytester-list border-1 border-gray-250 rounded-lg overflow-hidden px-4">
      <table className="figma-bytester-list__table table-auto w-full border-collapse" aria-label="Example static collection table">
        <thead className="figma-bytester-list__header text-sm captionNoMargin">
          <tr>
            <th className="figma-bytester-list__header-date px-3 py-2 font-medium w-[100px] text-left">{t("Date")}</th>
            <th className="figma-bytester-list__header-status px-3 py-2 font-medium w-[100px] text-left">{t("Status")}</th>
            <th className="figma-bytester-list__header-time px-3 py-2 font-medium w-[100px] text-left">{t("Time")}</th>
            <th className="figma-bytester-list__header-path px-3 py-2 font-medium min-w-[120px] text-left">{t("Path")}</th>
            {isReportHasVideo && <th className="figma-bytester-list__header-replay py-2 px-3 shrink-0 text-left font-medium">{t("Replay")}</th>}
          </tr>
        </thead>
        <tbody>
          {props.byTester.map((tester, index) => (
            <FigmaIndividualReportRow
              key={`FigmaIndividualReport-${index}`}
              blockId={props.blockId}
              tester={tester}
              onPreviewClick={props.onPreviewClick}
              prototype={props.prototype}
              pixelRatio={tester.devicePixelRatio || 1}
              size={tester.size}
              userAgent={tester.userAgent}
              prototypeType={tester.prototypeType}
              sharingToken={props.sharingToken}
              selecteTester={(tester) => {
                setSelectedTester(tester);
                setIsVideoModalOpen(true);
              }}
              isReportHasVideo={isReportHasVideo}
            />
          ))}
        </tbody>
      </table>

      <Modal width="95vw" height="90vh" isOpen={isVideoModalOpen} setIsOpen={setIsVideoModalOpen} background>
        <VideoPlayerResolver
          size={selectedTester.size}
          prototypeType={selectedTester.prototypeType}
          tester={selectedTester}
          closeModal={() => setIsVideoModalOpen(false)}
          screenVideo={false}
          blockId={props.blockId}
          pixelRatio={selectedTester.devicePixelRatio || 1}
          userAgent={selectedTester.userAgent}
          isVideoScrollEnabled={props.isSummaryReport}
        />
      </Modal>
    </div>
  );
}

function FigmaReportByScreen(props: Pick<IFigmaReportViewProps, 'byScreen' | 'onPreviewClick' | 'prototype'>) {
  return (
    <div className="flex flex-wrap mt-8 gap-4">
      {props.byScreen.screens.map((screen, index) => (
        <Preview
          key={index}
          prototype={props.prototype}
          screen={screen}
          width={96}
          onClick={() => props.onPreviewClick(screen.path)}
        />
      ))}
    </div>
  );
}


function FigmaIndividualReportRow(props:
  Pick<IFigmaReportViewProps, 'blockId' | 'onPreviewClick' | 'prototype' | 'sharingToken'> & {
    tester: IReportStateByTester,
    pixelRatio: number,
    size?: CanvasSize,
    prototypeType: PrototypeType,
    userAgent: string | undefined,
    selecteTester: (tester: IReportStateByTester) => void,
    isReportHasVideo: boolean,
  }
) {
  const { t } = useTranslation();

  function getVideoStatus() {
    const videoStatus = determineVideoStatus(props.tester);

    switch (videoStatus) {
      case VideoStatus.DONE:
        return (<button className="figma-bytester-item__result flex items-center gap-2" onClick={() => {
          props.selecteTester(props.tester);
        }}>
          <IconCirclePlay width={24} height={24} className="fill-current text-gray-800 hover:opacity-75 transition-opacity duration-75 ease-in-out" />
        </button>)
      case VideoStatus.PROCESSING:
        return (<div className="figma-bytester-item__result text-gray-600 flex">
          <Loader color={loaderColorMap["primary"]} className="mr-2" />
        </div>)
      case VideoStatus.UNAVAILABLE:
        return null;
      default:
        return null;
    }
  }

  return (
    <>
      <tr key={props.tester.answerId} className="border-t-1 border-gray-200">
        <td className="px-3 py-2 text-sm w-[128x]">{props.tester.date}</td>
        <td className="px-3 py-2 text-sm">
          {props.tester.givenUp ? (
            <span className="figma-bytester-item__result text-orange-600">
              <IconCancel fill="#F8511D" width={16} height={16} />
              {t("Gave up")}
            </span>
          ) : (
            <span className="figma-bytester-item__result flex items-center" style={{ color: '#22B466' }}>
              <IconTick className="fill-current mr-1" fill="#38A169" width={16} height={16} />
              {t("Succeeded")}
            </span>
          )}
        </td>
        <td className="px-3 py-2 text-sm">{props.tester.time}</td>
        <td className="px-3 py-4">
          <div className="figma-bytester-item__screens">
            <div className="screens-preview flex flex-wrap gap-y-2">
              {props.tester.screens.map((screen, index) => (
                <div className="screens-preview__item  " key={index}>
                  <Preview
                    prototype={props.prototype}
                    screen={screen}
                    width={40}
                    onClick={() => props.onPreviewClick(screen.path)}
                    showScreenName={false}
                  />
                </div>
              ))}
            </div>
          </div>
        </td>
        {props.isReportHasVideo && <td className="px-3 py-2 text-sm">
          {getVideoStatus()}
        </td>}
      </tr>

    </>
  );
}

FigmaPrototypeScreensReport.propTypes = {
  blockId: PropTypes.string,
  byScreen: PropTypes.shape({
    screens: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        image: PropTypes.string,
        path: PropTypes.array,
        nextScreenPath: PropTypes.array,
        prevScreenPath: PropTypes.array,
        clicks: PropTypes.array,
        stats: PropTypes.array,
      })
    ),
  }),
  byTester: PropTypes.arrayOf(
    PropTypes.shape({
      answerId: PropTypes.string,
      givenUp: PropTypes.bool,
      time: PropTypes.string,
      number: PropTypes.number,
      screens: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          image: PropTypes.string,
          path: PropTypes.array,
          nextScreenPath: PropTypes.array,
          prevScreenPath: PropTypes.array,
          clicks: PropTypes.array,
          stats: PropTypes.array,
        })
      ),
    })
  ),
  onPreviewClick: PropTypes.func,
};