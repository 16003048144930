import { baseConditions } from "./constants";
import { Block, BlockType, PreferenceBlock } from "../../../models/Test";
export function getBlockFilterConditions(
  blockType: BlockType,
  block: Block
): Array<{ name: string; value: string }> {

    if (blockType == BlockType.preference && (block as PreferenceBlock).comparisonType === "pairwise") {
      return baseConditions[blockType].filter((condition) => condition.value === "hasAnswer");
    }

  return baseConditions[blockType] || [];
}