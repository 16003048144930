import { IReportStateByTester } from "../FigmaReport";

export enum VideoStatus {
  DONE = "done",
  PROCESSING = "processing",
  UNAVAILABLE = "unavailable",
}


export function determineVideoStatus(tester: IReportStateByTester): VideoStatus {
  const isScreenVideoAvailable =
    (!!tester.recordResult?.canvasRecord && !tester.recordResult?.canvasErrorReason) ||
    (!!tester.recordResult?.screenRecord && !tester.recordResult?.screenErrorReason);

  const isRespondentVideoAvailable =
    (!!tester.recordResult?.audioRecord && !tester.recordResult?.audioErrorReason) ||
    (!!tester.recordResult?.cameraRecord && !tester.recordResult?.cameraErrorReason);

  return tester.recordResult
    ? (isScreenVideoAvailable || isRespondentVideoAvailable ? VideoStatus.DONE : VideoStatus.UNAVAILABLE)
    : (tester.withVideo ? VideoStatus.DONE : VideoStatus.UNAVAILABLE);
}