import React, { useState } from "react";
import { VidstackMaximize, VidstackMinimize } from '../../../../../icons';
import { MIME_TYPES } from "../../../../../Common/constants";

interface VideoFaceContainerProps {
  videoFaceRef: React.RefObject<HTMLVideoElement>,
  videoFaceUrl: string,
  isVisible: boolean,
  onError: () => void,
}

export default function VideoFaceContainer(props: VideoFaceContainerProps) {
  const [isMaxFaceSize, setIsMaxFaceSize] = useState(true);

  return (
    <div className={`video-container group overflow-hidden group absolute bottom-3 left-3 z-10 rounded-md ${isMaxFaceSize ? "w-[148px] h-[148px]" : "w-[64px] h-[64px]"}`}>
      <div className="absolute top-0 right-0 group-hover:opacity-100 opacity-0 transition-opacity duration-200 bg-white p-1 z-20 rounded-full cursor-pointer"
        style={{ display: props.isVisible ? "flex" : "none" }}
        onClick={() => setIsMaxFaceSize(!isMaxFaceSize)}
      >
        {isMaxFaceSize ? <VidstackMinimize /> : <VidstackMaximize />}
      </div>
      <video
        className="rounded-md"
        style={{
          objectFit: "cover",
          height: "100%",
          width: "auto",
          padding: "8px",
          borderRadius: isMaxFaceSize ? "32px" : "16px",
          display: props.isVisible ? "block" : "none"
        }}
        preload='auto'
        ref={props.videoFaceRef}
        onError={props.onError}
      >
        <source src={props.videoFaceUrl} type={MIME_TYPES.VIDEO_MP4} />
      </video>
    </div>
  )
}