import axios from "axios";
import { useState, useContext } from "react";
import { makeAuthorizedRequest } from "../../../http/Client";
import _ from 'lodash';
import { IPrototypeNode, IPrototypeData, IPrototypeDocument, IFigmaNodeResponse } from '../Models';

import AppContext from "../../../AppContext";

import i18n from "../../../i18n/config";
import { StorageAPI } from '../../../actions/StorageAPI';

export interface ImportPrototypeOptions {
	onPrototypeImported: (result: any) => void
	sendNotification: (type: "success" | "error" | "info", text: any) => void
}

export interface FigmaImagesResponse {
	err: null | any,
	images: Record<string, string | null>
};

export default function usePrototypeImport(options: ImportPrototypeOptions) {
	const [isImporting, setIsImporting] = useState(false);
	const [error, setError] = useState<string | null>(null);

	const { state } = useContext(AppContext);
	const userId = state.user?.userId;

	const importPrototype = async (figmaPrototypeLink: string, figmaAuthToken: string) => {
		setError(null);
		setIsImporting(true);
		try {
			const prototypeData = await extractPrototypeData(figmaPrototypeLink, userId);
			const getStructureTask = getPrototypeScrollableStructures(prototypeData.fileId, prototypeData.fileVersion, figmaAuthToken, prototypeData.prototypeScreens.map(s => s.id));
			const fileImagesChunks = _.chunk(prototypeData.prototypeScreens, 20);
			let uploadedProtoImages = {};
			const timestamp = new Date().getTime();
			const prototypeFilePath = `${prototypeData.fileId}/${timestamp}`;

			await Promise.all(fileImagesChunks.map(async (chunk) => {
				const images = await getFigmaFileImages(prototypeData.fileId, figmaAuthToken, chunk);
				const uploadedImages = await uploadFigmaFileImages(images.images, prototypeFilePath);
				Object.assign(uploadedProtoImages, uploadedImages);
			}));

			const scrollableStructures = await getStructureTask;
			insertScrollableStructuresToScreens(prototypeData.prototypeScreens, scrollableStructures);
			const prototypeDataFile = await uploadPrototypeDataFile({
				nodeImages: uploadedProtoImages,
				prototypeScreens: prototypeData.prototypeScreens,
				nodeNames: prototypeData.prototypeScreens.reduce((acc: { [key: string]: string }, screen) => {
					acc[screen.id] = screen.name;
					return acc;
				}, {}),
			}, prototypeFilePath);
			const result = {
				fileId: prototypeData.fileId,
				startNodeId: prototypeData.startingPointId,
				flowName: prototypeData.flowName,
				fileVersion: prototypeData.fileVersion,
				prototypeLink: figmaPrototypeLink,
				prototypeDataUrl: Object.values(prototypeDataFile)[0]
			};
			options.onPrototypeImported(result);
			options.sendNotification("success", i18n.t("Prototype imported"));
		} catch (error: any) {
			if (error.error_code) {
				setError(error.error_code);
			} else {
				setError("Failed to import prototype. Please, try again.");
			}
		}
		setIsImporting(false);
	};

	return { importPrototype, isImporting, error };
}

function insertScrollableStructuresToScreens(screens: { id: string }[], scrollableStructures: Map<string, IPrototypeNode>) {
	screens.forEach(s => {
		const structure = scrollableStructures.get(s.id);
		Object.assign(s, structure);
	});
}

async function getFigmaFileImages(fileId: string, figmaAuthToken: string, nodes: { id: string; }[], scale: number = 1, format: string = "png"): Promise<FigmaImagesResponse> {
	try {
		let idsString = nodes.map(n => n.id).join(',');
		let url = `https://api.figma.com/v1/images/${fileId}?ids=${idsString}&scale=${scale}&format=${format}`;

		const response = await axios.get<FigmaImagesResponse>(url, { responseType: 'json', headers: { "Authorization": `Bearer ${figmaAuthToken}` } });
		return response.data;
	} catch (error) {
		console.error(error);
		throw new Error("Failed to fetch file images from Figma");
	}
}

async function uploadFigmaFileImages(images: FigmaImagesResponse["images"], path: string) {
	const imagePromises = Object.entries(images).map(async ([key, url]) => {
		if (!url) return Promise.resolve(null);

		const response = await fetch(url);
		const blob = await response.blob();

		// The endpoint suddenly started returning an incorrect MIME type (binary/octet-stream) for image files.
		// As a result, files were being saved without an extension and were not displaying correctly.
		// This fix forces the MIME type to 'image/png' if an incorrect type is returned.
		const blobType = blob.type === 'binary/octet-stream' ? 'image/png' : blob.type;

		return {
			name: key,
			data: blob,
			mimeType: blobType,
			fileName: key,
		};
	});

	const files = await Promise.all(imagePromises);
	const uploadedFilesMap = await StorageAPI.uploadFiles(files.filter(file => file !== null) as any, { path: 'prototypes/native/' + path });
	return uploadedFilesMap;
}

async function uploadPrototypeDataFile(data: any, path: string) {
	return await StorageAPI.uploadFile('prototypeData', JSON.stringify(data), 'application/json', path + '.json', { path: 'prototypes/native/' + path });
}

async function extractPrototypeData(figmaPrototypeLink: string, userId: string): Promise<IPrototypeData> {
	const url = new URL(figmaPrototypeLink);
	const fileId = url.pathname.split('/')[2];
	const startingNodeId = url.searchParams.get("starting-point-node-id");

	const body = {
		fileId: fileId || null,
		startingNodeId: startingNodeId ? decodeURIComponent(startingNodeId) : null,
	};
	let response;
	response = await makeAuthorizedRequest(`/api/v1/figma/extractPrototypeData?fileId=${fileId}&startingNodeId=${startingNodeId}&userId=${userId}`, "POST", body);
	if (!response.ok) {
		const errorData = await response.json() as { result: 'error'; code: string; message: string };
		throw errorData;
		// throw ({ message: errorData.message, details: errorData.details });
	}
	const data = await response.json();
	return data as IPrototypeData;
}

/**
 * This function gets the prototype screens structure which reflects how scrollable nodes are nested in the screens.
 */
async function getPrototypeScrollableStructures(fileId: string, fileVersion: string, authToken: string, screenIds: string[]) {
	const chunks = _.chunk(screenIds, 10);
	const promises = chunks.map(chunk => getPrototypeScreensData(fileId, fileVersion, authToken, chunk));
	const results = await Promise.all(promises);

	// flatten the results
	const scrollableStructures = results.flat();
	const scrollableStructuresMap = new Map<string, IPrototypeNode>();
	const scrollableStructuresList = getScreenScrollableStructures(scrollableStructures);
	scrollableStructuresList.forEach(s => scrollableStructuresMap.set(s.id, s.structure));
	return scrollableStructuresMap;
}

function getScreenScrollableStructures(screenObjs: IPrototypeDocument[]) {
	return screenObjs.map(doc => getScrollableNodesStructure(doc));
}

async function getPrototypeScreensData(fileId: string, fileVersion: string, authToken: string, screenIds: string[]) {
	// get node data from figma api
	const response = await axios.get<IFigmaNodeResponse>(`https://api.figma.com/v1/files/${fileId}/nodes?ids=${screenIds.join(',')}&version=${fileVersion}`, {
		headers: {
			"Authorization": `Bearer ${authToken}`
		}
	});

	const data = response.data;
	return Object.values(data.nodes);
}

function getScrollableNodesStructure(document: IPrototypeDocument) {
	var root = document.document;
	// traverse the tree in depth and build copy of the tree with only scrollable nodes
	// служебный стек для прохода по всему дереву
	var stack = [root];
	// список найденных скроллящихся слоев, в него добавляются все скроллящиеся слои по мере прохождения по дереву (включая дочерние)
	var scrollableLayers = [] as IPrototypeNode[];
	var currentLayerIndex = -1;

	// стек всех нод, у которых есть дети (фрейм или группа и пр.)
	// в стек добавляется текущая вершина (слой) дерева и информация о количестве детей
	// элемент не убирается из стека, пока не будут обработаны все его дети.
	// В то же время, этот стек используется для нахождения ближайшего родителя, у которого есть скролл
	var visitedNodesStack = [] as { id: string, size: number, isScrollable: boolean }[];
	while (stack.length > 0) {
		var node = stack.pop() as IPrototypeNode;
		var lastVisitedNode = visitedNodesStack[visitedNodesStack.length - 1];

		if (node.id === lastVisitedNode?.id && lastVisitedNode?.size === 0) {
			visitedNodesStack.pop();
			const prevNode = visitedNodesStack[visitedNodesStack.length - 1];
			if (prevNode) prevNode.size--;

			if (lastVisitedNode.isScrollable) {
				currentLayerIndex--;
			}
			continue;
		}

		var isScrollable = false;
		// Если текущий нод - это фрейм со скроллом или это просто корневой фрейм, то добавляем его в список скроллящихся слоев
		if (node.id === root.id || node.overflowDirection === "VERTICAL_SCROLLING" || node.overflowDirection === "HORIZONTAL_SCROLLING") {
			var model = getNodeModel(node);
			scrollableLayers.push(model);

			const scrollablesStack = visitedNodesStack.filter(n => n.isScrollable);
			// Находим ближайшего родителя, у которого есть скролл
			const lastScrollable = scrollablesStack[scrollablesStack.length - 1];
			// Затем среди всех найденных скроллящихся нод ищем этого родителя и добавляем текущий нод в его дети
			if (lastScrollable) scrollableLayers.find(l => l.id === lastScrollable.id)?.children.push(model);
			isScrollable = true;
		}

		if (node.isFixed) {
			var model = getNodeModel(node);

			const scrollablesStack = visitedNodesStack.filter(n => n.isScrollable);
			// Находим ближайшего родителя, у которого есть скролл
			const lastScrollable = scrollablesStack[scrollablesStack.length - 1];
			// Затем среди всех найденных скроллящихся нод ищем этого родителя и добавляем текущий нод в его дети
			if (lastScrollable) scrollableLayers.find(l => l.id === lastScrollable.id)?.fixedChildren.push(model);
		}

		if (node === null || node === void 0 ? void 0 : node.children) {
			// Если у нода есть дети, то запихиваем его обратно в стек и добавляем в стек слоев с детьми
			stack.push(node);
			visitedNodesStack.push({ id: node.id, size: node.children.length, isScrollable: isScrollable });
			for (var i = 0; i < node.children.length; i++) {
				stack.push(node.children[i]);
			}
		}
		else {
			var lastVisitedNode = visitedNodesStack[visitedNodesStack.length - 1];
			lastVisitedNode.size--;
		}
	}
	return { id: document.document.id, structure: scrollableLayers[0] };
}

function getNodeModel(node: IPrototypeNode): IPrototypeNode {
	return {
		id: node.id,
		name: node.name,
		type: node.type,
		scrollBehavior: node.scrollBehavior,
		absoluteBoundingBox: node.absoluteBoundingBox,
		absoluteRenderBounds: node.absoluteRenderBounds,
		clipsContent: node.clipsContent,
		overflowDirection: node.overflowDirection,
		children: [],
		fixedChildren: []
	} as IPrototypeNode;
}