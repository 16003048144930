import React, { useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';

import Loader from '../../../Loader';

import PlayerControls from './controls/PlayerControls';
import { ErrorVideoMessage, Header, Transcriptions, VideoFaceContainer } from './components';
import { RecordTiming } from '../../../Common/RecordResult';
import { useVideoContent } from './hooks';

export default function ScreenRecorderPlayer(props: {
  recordingId: string,
  sharingToken?: string,
  date: string,
  closeModal: () => void,
  screenRecord: RecordTiming | null,
  cameraRecord: RecordTiming | null,
  microphoneRecord: RecordTiming | null,
  isVideoScrollEnabled: boolean,
  duration: number,
}) {
  const containerRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const hasTranscriptions = false;

  const { videoUrl,
    videoFaceUrl,
    videoFaceRef,
    videoRef,
    withRespondentRecord,
    videoFaceError,
    videoScreenError,
    isPlayerLoading,
    handleReduceLoadedVideoCount,
    handleVideoError,
  } = useVideoContent(props.recordingId,
    !!props.screenRecord,
    !!props.cameraRecord,
    !!props.microphoneRecord,
    props.sharingToken);

  const videoOffsets = useMemo(() => {
    const mainRecordTiming = !videoFaceError ? (props.cameraRecord || props.microphoneRecord) : null;

    if (!props.screenRecord || !mainRecordTiming) return {
      startOffset: 0,
      duration: 0
    };

    return {
      startOffset: Math.abs(props.screenRecord.start - mainRecordTiming.start) / 1000,
      duration: Math.abs(props.screenRecord.stop - props.screenRecord.start) / 1000
    }
  }, [props.screenRecord, props.cameraRecord, props.microphoneRecord]);

  const [longestVideoRef, shortestVideoRef] = useMemo(() => {
    if (videoScreenError) {
      return [videoFaceRef, undefined];
    }

    const cameraDuration = props.cameraRecord ? props.cameraRecord.stop - props.cameraRecord.start : 0;
    const microphoneDuration = props.microphoneRecord ? props.microphoneRecord.stop - props.microphoneRecord.start : 0;
    const screenDuration = props.screenRecord ? props.screenRecord.stop - props.screenRecord.start : 0;

    if (cameraDuration > screenDuration || microphoneDuration > screenDuration) {
      return [videoFaceRef, videoRef];
    } else {
      return [videoRef, videoFaceRef];
    }
  }, [props.screenRecord, props.cameraRecord, props.microphoneRecord, videoScreenError, videoFaceError]);

  useEffect(() => {
    if (!containerRef.current || !videoRef.current) return;
    if (withRespondentRecord && !videoFaceRef.current) return;

    videoRef.current?.addEventListener('loadedmetadata', handleReduceLoadedVideoCount);

    if (withRespondentRecord) {
      videoFaceRef.current?.addEventListener('loadedmetadata', handleReduceLoadedVideoCount);
    }

    return () => {
      videoRef.current?.removeEventListener('loadedmetadata', handleReduceLoadedVideoCount);

      if (withRespondentRecord) {
        videoFaceRef.current?.removeEventListener('loadedmetadata', handleReduceLoadedVideoCount);
      }
    };
  }, [containerRef.current, videoRef.current, videoFaceRef.current, withRespondentRecord, videoScreenError]);

  return (
    <div className="screen-recorder-player w-full h-full bg-black">
      {isPlayerLoading && <Loader />}
      <div className={clsx("screen-recorder-player__container w-full h-full", isPlayerLoading ? "invisible absolute" : "visible relative")}>
        <Header date={props.date} closeModal={props.closeModal} isVideoScrollEnabled={props.isVideoScrollEnabled} />

        <div
          id="replayContainer"
          className="screen-recorder-player__replay-container video-replayer relative overflow-hidden bg-black"
          style={{ height: "calc(100% - 125px)" }}
          ref={containerRef}
        >
          {hasTranscriptions && <div className='screen-recorder-player__transcriptions absolute top-5 right-5 z-20'> <Transcriptions /> </div>}

          {withRespondentRecord && <VideoFaceContainer 
            videoFaceRef={videoFaceRef}
            videoFaceUrl={videoFaceUrl}
            isVisible={!!props.cameraRecord && (!props.screenRecord || videoScreenError) && !videoFaceError}
            onError={() => handleVideoError("face")}
          />}

          {!props.screenRecord && !videoScreenError && <ErrorVideoMessage />}
          {videoScreenError && <ErrorVideoMessage type='notFound' />}

          <div
            className="screen-recorder-player__video-wrapper video-replayer__wrapper absolute flex justify-center items-center w-full h-full"
            ref={wrapperRef}
          >
            <video
              className="screen-recorder-player__video video-replayer__video w-full h-auto max-w-full max-h-full"
              ref={videoRef}
              preload='auto'
              onError={() => handleVideoError("screen")}
            >
              <source src={videoUrl} type="video/webm" />
            </video>
          </div>
        </div>

        {!isPlayerLoading && (!videoScreenError || !videoFaceError) &&
          <PlayerControls
            additionalVideoRef={(props.screenRecord && withRespondentRecord) ? shortestVideoRef : undefined}
            mainVideoRef={longestVideoRef}
            hasAudio={!!props.microphoneRecord && !videoFaceError}
            videoOffsets={videoOffsets}
            duration={props.duration}
          />
        }
      </div>
    </div>
  );
}

