import { useEffect, useState } from "react";
import { StorageAPI } from '../../../actions/StorageAPI';

export function useDownloadRecording(options: {
  blockId: string;
  answerId: string;
}) {

  const [events, setEvents] = useState<any>(null);
  const [error, setError] = useState<string | null>("");

  useEffect(() => {
    StorageAPI.getRecording(options)
      .then(async (events) => {
        // Удаляет элемент записи камеры из видео
        if (events?.[1]?.data?.node) {
          const targetNode = events[1].data.node.childNodes?.[1]?.childNodes?.[2]?.childNodes?.[3]?.childNodes?.[0]?.childNodes?.[0]?.childNodes?.[0];

          if (targetNode?.childNodes?.[1]?.attributes?.id === 'figma-face-recording-container') {
            targetNode.childNodes.splice(1, 1);
          }
        }

        setEvents(events);
      })
      .catch((error) => {
        console.error(error);
        setError("UNABLE_TO_LOAD_RECORDING");
      });
  }, [options.blockId, options.answerId]);

  return { events, error };
}
