import React from "react";
import RadioButton from "../Common/RadioButton";
import { RadioGroup } from "@nextui-org/radio";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import * as Icons from "../../icons";

type RadioSelectProps = {
  name: string;
  value?: string;
  options: {
    [value: string]: {
      label: string;
      icon?: keyof typeof Icons;
      caption?: string; // Add caption to options
    };
  };
  onChange: (value: string) => void;
  columns?: 1 | 2 | 3 | 4;
  disabled?: boolean;
};

function RadioSelect({ columns = 1, disabled = false, ...props }: RadioSelectProps) {
  const { t } = useTranslation();

  const gridClasses = {
    1: "grid-cols-1",
    2: "grid-cols-1 sm:grid-cols-2",
    3: "grid-cols-1 sm:grid-cols-2 md:grid-cols-3",
    4: "grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4",
  };

  return (
    <RadioGroup
      classNames={{
        wrapper: clsx(
          "grid gap-4",
          disabled && "pointer-events-none cursor-not-allowed opacity-50",
          gridClasses[columns],
          "auto-rows-fr"
        ),
      }}
      onValueChange={props.onChange}
      defaultValue={props.value}
      isDisabled={disabled}
    >
      {Object.entries(props.options).map(([value, { label, icon, caption }]) => {
        const Icon = icon ? Icons[icon] : null;
        return (
          <RadioButton key={`${props.name}-${value}`} value={value} isDisabled={disabled}>
            <div className="flex flex-col">
              <div className="flex items-center">
                {Icon && <Icon className="mr-2 w-5 h-5" />}
                {label}
              </div>
              {caption && (
                <div className="text-sm text-gray-600 mt-1 font-normal">{caption}</div>
              )}
            </div>
          </RadioButton>
        );
      })}
    </RadioGroup>
  );
}

export default RadioSelect;