import React, { useEffect, useState } from "react";

import { VidstackMuted, VidstackVolume } from "../../../../../icons";

type MuteUnmuteButtonProps = {
  videoRef: React.RefObject<HTMLVideoElement>
}

export default function MuteUnmuteButton(props: MuteUnmuteButtonProps) {
  const [isMuted, setIsMuted] = useState(false);

  function toggleMute() {
    if (props.videoRef.current) {
      props.videoRef.current.muted = !isMuted;
    }
    setIsMuted(!isMuted);
  }

  if (!props.videoRef.current) return <VidstackMuted className="fill-current text-white" />;

  return (
    <button onClick={toggleMute}>
      {isMuted ? <VidstackMuted className="fill-current text-white" /> : <VidstackVolume className="fill-current text-white" />}
    </button>
  )
}