import { IconPointerDesktop } from "../../../../../icons";

import React from "react";

type PointerProps = {
  pointerRef: any;
  isMobile: boolean;
};

export default function Pointer({ pointerRef, isMobile }: PointerProps) {
  if (!isMobile) {
    return (
      <IconPointerDesktop className="video-replayer__pointer w-8 h-8 -m-4 absolute opacity-0" id="pointer" ref={pointerRef} />
    )
  }

  return (
    <div
      className="video-replayer__pointer w-6 h-6 rounded-full bg-red-500 -m-3 absolute transition-all ease-linear duration-[10ms] opacity-0"
      id="pointer"
      ref={pointerRef}
    />
  )
}