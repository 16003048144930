import React from "react";
import { IconClose } from "../../../../../icons";
import { t } from "i18next";

type HeaderProps = {
  date: string;
  closeModal: () => void;
  isVideoScrollEnabled: boolean;
}

export default function Header(props: HeaderProps) {
  return (
    <div className="player-header w-full flex justify-between items-center p-5 border-b-1 border-gray-800">
      <div className="player-header__title text-white text-lg font-bold"> {props.date} </div>
      <div className="flex items-center gap-5">
        {props.isVideoScrollEnabled && (
          <p className="player-header__description text-gray-600 text-xs flex items-center gap-2">
            {" "}
            {t("Press")}
            <span className="border-1 border-gray-700 p-[2px] rounded-md"> Esc </span> {t("to close")},
            <span className="border-1 border-gray-700 py-[2px] px-[4px] rounded-md">←</span>
            <span className="border-1 border-gray-700 py-[2px] px-[4px] rounded-md">→</span>
            {t("to switch between recordings")}
          </p>
        )}
        <button onClick={props.closeModal}>
          <IconClose className="fill-current text-white hover:opacity-75 transition-opacity duration-75 ease-in-out" />
        </button>
      </div>
    </div>
  );
}