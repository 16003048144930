import { useEffect } from "react";
import { IReportStateByTester } from "../FigmaReport";

export default function useKeyDownHandler(
  isSummaryReport: boolean,
  byTester: IReportStateByTester[],
  selectedTester: IReportStateByTester,
  handleArrow: React.Dispatch<React.SetStateAction<IReportStateByTester>>,
  handleEscape: () => void
) {

  function getNextTester(byTester: IReportStateByTester[], currentTester: IReportStateByTester, direction: 'next' | 'prev') {
    const currentIndex = byTester.findIndex(t => t.answerId === currentTester.answerId);
    let newIndex = currentIndex;
    const testerHasVideo = (tester: IReportStateByTester) => tester.withVideo || tester.recordResult?.audioRecord || tester.recordResult?.screenRecord || tester.recordResult?.cameraRecord || tester.recordResult?.canvasRecord;

    do {
      newIndex = direction === 'next' ? newIndex + 1 : newIndex - 1;
      const tester = byTester[newIndex];
      if (tester && testerHasVideo(tester)) {
        return tester;
      }
    } while (newIndex >= 0 && newIndex < byTester.length);
    return currentTester;
  }

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowRight' || event.key === 'ArrowLeft') {
        const direction = event.key === 'ArrowRight' ? 'next' : 'prev';
        const currentTester = byTester.find(t => t.answerId === selectedTester.answerId);

        if (currentTester) {
          const nextTester = getNextTester(byTester, currentTester, direction);
          if (nextTester) {
            handleArrow(nextTester);
          }
        }
      }

      if (event.key === "Escape") {
        handleEscape();
      }
    };

    if (isSummaryReport) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (isSummaryReport) {
        window.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [isSummaryReport, byTester, selectedTester, handleArrow, handleEscape]);
}

