import { makeAuthorizedRequest } from "../http/Client";

export class UserActions {
	public static async setFigmaAuth(userId: string, code: string | null, state: string | null) {
		const body = {
			code: code,
			state: state,
		};
		const response = await makeAuthorizedRequest("/api/v1/figma/oauth/getAccessToken", "POST", body);
		if (response.ok) {
			const data = await response.json();
			return {
				type: "SET_USER_DATA",
				payload: {
				  userId: userId,
				  data: data,
				}
			}
		}
		throw new Error("http_error");
	}
}
