import { useQuery } from "@tanstack/react-query";
import { IRecordEvent } from "../../types";
import { makeRequest } from "../../../../../http/Client";
import { CanvasSize } from "../../../../../models/Response";
import { FigmaFlowReplayer } from "../../replay";
import { useRef } from "react";

export default function useClickEvents(recordingId: string, sharingToken: string | undefined, size: CanvasSize | undefined) {
  const clicksQuery = useQuery({
    queryKey: ['clicks', recordingId],
    queryFn: () => makeRequest(`/api/v1/answers/${recordingId}/clicks?sharingToken=${sharingToken}`).then(res => res.json() as Promise<{ events: IRecordEvent[][], eventsTS: number, eventsStopTs: number, videoTS: number, videoStopTs: number }>),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
  });

  const clicks = (clicksQuery.data?.events || clicksQuery.data || []) as IRecordEvent[][];
  const startTs = clicksQuery.data?.eventsTS || 0;
  const endTs = clicksQuery.data?.eventsStopTs || 0;

  const player = useRef<{ replayer: FigmaFlowReplayer | null }>({ replayer: null });

  let indentX = 0;
  let indentY = 0;

  if (size && size.bodySize?.width > size.canvasDisplaySize.width) {
    indentX = (size.bodySize.width - size.canvasDisplaySize.width) / 2;
  }
  if (size && size.bodySize?.height > size.canvasDisplaySize.height) {
    indentY = (size.bodySize.height - size.canvasDisplaySize.height) / 2;
  }

  return {
    clicks,
    startTs,
    endTs,
    indentX,
    indentY,
    player,
    data: clicksQuery.data,
  };
}